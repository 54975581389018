<div class="menu" (click)="toggleMenu()">
  <i class="menu-icon"></i>
</div>
<div *ngIf="menuOpened$ | async" class="fake-bg" (click)="toggleMenu()"></div>
<a class="flex-y-center logo" href=".">
  <svg viewBox="0 0 500 500" width="40" height="40" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M250.696,82 L91,138.2 L116.235,347.426 L250.864,421.421 L386.186,346.424 L411.413,137.205 L250.696,82"
      fill="#FFFFFF"
    ></path>
    <path d="M395.484,149.298 L250.323,99.795 L250.323,403.742 L371.974,336.416 L395.484,149.298" fill="#a80e00"></path>
    <path d="M108.92,150.189 L130.544,337.309 L250.32,403.742 L250.32,99.789 L108.92,150.189" fill="#e01600"></path>
    <path d="M210,180 L210,300 L310,240 z" fill="#FFFFFF"></path>
  </svg>
  <h1>Angular demo</h1>
</a>

<div
  class="ml-auto flex-y-center h-100"
  *ngIf="{ isLesson: isLesson$ | async, isLessonRecording: isLessonRecording$ | async, loading: loading$ | async, isProjectDirty: isProjectDirty$ | async, canEdit: canEdit$ | async, room: liveService.room$ | async, recordState: recordState$ | async } as vm"
>
  <ng-template *ngIf="authenticated$ | async" ng-run-container="header-menu"></ng-template>
  <div class="mr-1 cursor-pointer live-icon" *ngIf="(vm.canEdit || vm.room) && !vm.isLessonRecording && !vm.isLesson">
      <ng-run-icon class="cursor-pointer live-btn"
                   [class.live-btn--enabled]="vm.room"
                   [title]="!vm.canEdit ? '' : ((!vm.room ? 'Start' : 'Stop') + ' live collaboration')"
                   name="live"
                   width="24"
                   height="24"
                   (click)="vm.canEdit && toggleLive()"></ng-run-icon>
      <i *ngIf="vm.room">
        {{ vm.room.users.length }}
      </i>
      <div class="live-menu" *ngIf="vm.room">
          <ul>
            <li *ngFor="let client of vm.room.users" class="client" (click)="gotoUser(client)">
              <div class="mr-1 client-photo" >
                <img width="35" height="35"  [src]="client.photoUrl" [alt]="client.displayName"/>
                <span class="host-marker" *ngIf="vm.room.owner.conId === client.conId">host</span>
              </div>
               <div class="client-info">
                 {{ client.displayName || client.email }}
                 <span *ngIf="vm.room.currentUser.conId === client.conId">(you)</span>
               </div>
            </li>
          </ul>
      </div>
  </div>
 <div class="loader" *ngIf="vm.loading"></div>
  <ng-container *ngIf="!vm.isLesson && !vm.isLessonRecording">
    <button
      *ngIf="vm.canEdit"
      class="top-button top-button--save ml-2"
      [title]="vm.loading || !vm.isProjectDirty ? 'There is no changes' : 'Save your changes'"
      [disabled]="vm.loading || !vm.isProjectDirty"
      (click)="saveOrForkProject()"
    >
      Save
    </button>
    <button
      *ngIf="authenticated$ | async"
      [disabled]="vm.loading"
      class="top-button ml-2 trigger"
      ng-run-tooltip="Save your changes as a fork of this project"
      (click)="saveOrForkProject(true)"
    >
      Fork
    </button>
    <ng-container *ngIf="isTestsSupported$ | async">
      <div
        *ngIf="{ testEnabled: testEnabled$ | async } as vm"
        class="test-mode-switcher"
        [attr.title]="vm.testEnabled ? 'Disable tests' : 'Enable tests'"
        [class.test-mode-switcher--enabled]="vm.testEnabled"
        (click)="toggleTest()"
      ></div>
    </ng-container>
    <ng-run-layout-picker class="mr-1"></ng-run-layout-picker>
  </ng-container>

  <ng-run-theme-picker class="h-100"></ng-run-theme-picker>
  <ng-run-user-panel class="ml-1 h-100"></ng-run-user-panel>
</div>
