import { Injectable } from '@angular/core';
import { map, startWith, tap } from 'rxjs/operators';
import { Firestore, doc, setDoc } from '@angular/fire/firestore';
import {
  Auth,
  authState,
  signOut,
  signInWithPopup,
  AuthProvider,
  UserInfo,
  GithubAuthProvider,
} from '@angular/fire/auth';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  authState$ = authState(this.afAuth);

  user$ = this.authState$.pipe(
    startWith(JSON.parse(localStorage.getItem('profile')) as UserInfo),
    tap((user) => {
      this.user = user;
    })
  );

  authenticated$ = this.user$.pipe(map(Boolean));

  user: UserInfo;

  constructor(public afAuth: Auth, private afs: Firestore) {}

  signInWithGithub(): Promise<any> {
    return this.signIn(new GithubAuthProvider());
  }

  async signOut(): Promise<void> {
    await signOut(this.afAuth);
    localStorage.removeItem('profile');
  }

  private signIn(provider: AuthProvider): Promise<any> {
    return signInWithPopup(this.afAuth, provider)
      .then((credential) => {
        return this.updateUserData(credential.user);
      })

      .catch((error) => console.log('ERROR @ AuthService#signIn() :', error));
  }

  private updateUserData({ uid, displayName, photoURL, email, phoneNumber, providerId }: UserInfo) {
    const userDoc = doc(this.afs, `users/${uid}`);

    const data: UserInfo = {
      uid,
      displayName: displayName || email,
      ...(photoURL && { photoURL }),
      ...(email && { email }),
      ...(providerId && { providerId }),
      ...(phoneNumber && { phoneNumber }),
    };

    localStorage.setItem('profile', JSON.stringify(data));

    return setDoc(userDoc, { ...data }, { merge: true });
  }
}
