import { Component } from '@angular/core';

import { PlaygroundStore, ProjectStore } from '@ng-run/playground-store';
import { EditorWorker } from '../../../editor/editor.worker';
import { MonacoEditorStore } from '../../../editor/monaco.store';
import { LiveService } from '../../../editor/live/live.service';

@Component({
  selector: 'ng-run-solution-settings',
  templateUrl: './solution-settings.component.html',
  styleUrls: ['./solution-settings.component.scss'],
})
export class SolutionSettingsComponent {
  ngVersion$ = this.projectStore.ngVersion$;

  aotEnabled$ = this.projectStore.aotEnabled$;
  vimEnabled$ = this.projectStore.vimEnabled$;
  recording$ = this.projectStore.recording$;
  reloadMode$ = this.playgroundStore.reloadMode$;

  constructor(
    private projectStore: ProjectStore,
    private editorWorker: EditorWorker,
    private monacoStore: MonacoEditorStore,
    private playgroundStore: PlaygroundStore,
    public liveService: LiveService
  ) {}

  toggleAot() {
    this.projectStore.toggleAotStatus();

    const deps = this.projectStore.state.dependencies;
    const packagesToUpdate = [];

    Object.keys(deps).forEach((depName) => {
      if (!depName.startsWith('@angular')) {
        return;
      }

      const depPayload = {
        name: depName,
        version: deps[depName].version,
        prevVersion: deps[depName].version,
      };

      packagesToUpdate.push(depPayload);

      this.monacoStore.clearExportsFor(depName);
      this.monacoStore.disposeExtraLibsForPackage(depName);
    });

    this.editorWorker.installState$.next(packagesToUpdate.map((pkg) => `${pkg.name}@${pkg.version}`));
    this.editorWorker.post({
      type: 'UPDATE_DEPS',
      payload: {
        aot: this.projectStore.state.aot ? this.projectStore.getAotVersion() : false,
        deps: packagesToUpdate,
        force: true,
        files: this.projectStore.state.files,
      },
    });
  }

  toggleVim() {
    this.projectStore.toggleVimMode();
  }

  toggleRecording() {
    this.projectStore.toggleRecording();
  }

  setReloadMode(event: any) {
    this.playgroundStore.setReloadMode(event.target.value);
  }
}
