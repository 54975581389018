import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { scan, take, tap } from 'rxjs/operators';
import {
  Firestore,
  Query,
  collection,
  collectionData,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from '@angular/fire/firestore';

import { AuthService } from '@ng-run/auth';
import { timeSince } from '@ng-run/utils';
import { DBProject } from './project.service';

@Injectable({
  providedIn: 'root',
})
export class PaginationService {
  data: Observable<any>;
  done: Observable<boolean>;
  loading: Observable<boolean>;

  private _done = new BehaviorSubject(false);
  private _loading = new BehaviorSubject(false);
  private _data = new BehaviorSubject([]);

  private query: QueryConfig;

  constructor(private afs: Firestore, private auth: AuthService) {
    this.done = this._done.asObservable();
    this.loading = this._loading.asObservable();
  }

  init(path, field, opts?) {
    this.query = {
      path,
      field,
      limit: 20,
      reverse: false,
      prepend: false,
      ...opts,
    };

    const first = query(
      collection(this.afs, this.query.path),
      where('authorId', '==', this.auth.user ? this.auth.user.uid : ''),
      orderBy(this.query.field, this.query.reverse ? 'desc' : 'asc'),
      limit(this.query.limit)
    );

    this.mapAndUpdate(first);

    // Create the observable array for consumption in components
    this.data = this._data.asObservable().pipe(
      scan((acc, val) => {
        return this.query.prepend ? val.concat(acc) : acc.concat(val);
      })
    );
  }

  more() {
    const cursor = this.getCursor();

    const more = query(
      collection(this.afs, this.query.path),
      where('authorId', '==', this.auth.user ? this.auth.user.uid : ''),
      orderBy(this.query.field, this.query.reverse ? 'desc' : 'asc'),
      limit(this.query.limit),
      startAfter(cursor)
    );
    this.mapAndUpdate(more);
  }

  reset() {
    this._data.next([]);
    this._done.next(false);
  }

  // Determines the doc snapshot to paginate query
  private getCursor() {
    const current = this._data.value;
    if (current.length) {
      return this.query.prepend ? current[0].doc : current[current.length - 1].doc;
    }
    return null;
  }

  // Maps the snapshot to usable format the updates source
  private async mapAndUpdate(query: Query<any>) {
    if (this._done.value || this._loading.value) {
      return;
    }

    this._loading.next(true);

    const documentSnapshots = await getDocs(query);

    let values = documentSnapshots.docs.map((doc) => {
      const data = doc.data();
      return { id: data.id, title: data.title, timeAgo: timeSince(data.createdAt), doc };
    });

    // If prepending, reverse array
    values = this.query.prepend ? values.reverse() : values;

    // update source with new values, done loading
    this._data.next(values);
    this._loading.next(false);

    // no more values, mark done
    if (!values.length) {
      this._done.next(true);
    }
  }
}

// Options to reproduce firestore queries consistently
interface QueryConfig {
  path: string; // path to collection
  field: string; // field to orderBy
  limit?: number; // limit per query
  reverse?: boolean; // reverse order?
  prepend?: boolean; // prepend to source?
}
