import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { filter, take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

import { ProjectStore } from '@ng-run/playground-store';
import { AuthService } from '@ng-run/auth';
import { generateRandomID, getParameterByName, updateQueryStringParameter } from '@ng-run/utils';
import { MonacoEditorStore } from '../monaco.store';
import { EditorWorker } from '../editor.worker';

import { NotificationService } from '@ng-run/shared';

import { LiveUser, Room } from './live.models';
import { LiveSessionManager } from './live-session.manager';
import IDisposable = monaco.IDisposable;
import { loadEditor } from '../loader/editor.loader';

const LIVE_QUERY_PARAM = 'live';

@Injectable({
  providedIn: 'root',
})
export class LiveService {
  roomId$ = new BehaviorSubject(getParameterByName(LIVE_QUERY_PARAM));

  room$: BehaviorSubject<Room> = new BehaviorSubject(null);

  get roomId() {
    return this.roomId$.value;
  }
  set roomId(value) {
    this.roomId$.next(value);
  }

  activeEditor$ = new BehaviorSubject(null);

  liveManager: LiveSessionManager;

  liveManagerPromise$: Promise<LiveSessionManager>;

  get isConnected() {
    return this.roomId && this.liveManager && this.liveManager.isConnected;
  }

  get isApplyingOperation() {
    return this.liveManager && this.liveManager.isApplyingOperation;
  }

  private _listeners: { [uri: string]: IDisposable } = Object.create(null);

  constructor(
    private authService: AuthService,
    private location: Location,
    private projectStore: ProjectStore,
    private monacoStore: MonacoEditorStore,
    private editorWorker: EditorWorker,
    private notificationService: NotificationService
  ) {
    authService.authState$.subscribe(user => {
      if (!this.roomId) {
        return;
      }
      if (!user) {
        if (this.isConnected) {
          this.liveManager.disconnect();
        } else {
          this.notificationService.notify(
            {
              type: 'error',
              message: `You can't access this session. Please sign in`,
            },
            10000
          );
        }
      } else {
        this.activeEditor$
          .pipe(filter(Boolean))
          .pipe(take(1))
          .subscribe(() => {
            this.getLiveManager().then(liveManager => {
              liveManager.connect(user, this.roomId).then();
            });
          });
      }
    });

    this.listenToModelChanges().then();
  }

  detectChangeCursor(editor, selectionChange, openedFile) {
    if (this.isConnected) {
      this.liveManager.detectChangeCursor(editor, selectionChange, openedFile);
    }
  }

  async listenToModelChanges() {
    await loadEditor();
    monaco.editor.onDidCreateModel(model => {
      const path = model.uri.path.substring(4);
      if (this.isConnected && this.projectStore.state && !Object.keys(this.projectStore.state.files).includes(path)) {
        this.liveManager.send('addModel', this.roomId, { path, contents: model.getValue() });
      }
      this._listeners[model.uri.toString()] = model.onDidChangeContent(e => {
        if (this.isApplyingOperation) {
          return;
        }
        const newValue = model.getValue();
        const fileToChange = this.projectStore.state.files[path];
        if (this.isConnected) {
          if (fileToChange && fileToChange.contents !== newValue) {
            this.liveManager.sendChangeOperations(e, fileToChange);
          }
        }
        if (fileToChange && fileToChange.contents !== newValue) {
          this.editorWorker.detectChanges(path, newValue);
        }
      });
    });
    monaco.editor.onWillDisposeModel(model => {
      const path = model.uri.path.substring(4);
      if (this.isConnected) {
        this.liveManager.send('removeItem', this.roomId, { path, type: 'file' });
      }
      let uriStr = model.uri.toString();
      let listener = this._listeners[uriStr];
      if (listener) {
        listener.dispose();
        delete this._listeners[uriStr];
      }
    });
  }

  getLiveManager() {
    if (!this.liveManagerPromise$) {
      this.liveManagerPromise$ = import(/* webpackChunkName: "live-session" */ './live-session.manager').then(m => {
        this.liveManager = new m.LiveSessionManager(
          this.location,
          this.projectStore,
          this.monacoStore,
          this.editorWorker,
          this.notificationService,
          this.activeEditor$,
          this.room$,
          this.roomId$
        );
        return this.liveManager;
      });
    }
    return this.liveManagerPromise$;
  }

  async toggleLive() {
    this.roomId = this.roomId ? null : await generateRandomID();
    document.body.classList.toggle('live-enabled', !!this.roomId);
    this.location.replaceState(updateQueryStringParameter(LIVE_QUERY_PARAM, this.roomId));
    this.getLiveManager().then(liveManager => {
      if (this.roomId) {
        this.projectStore.save(false).then();
        liveManager.connect(this.authService.user, this.roomId, true).then();
      } else {
        liveManager.disconnect();
      }
    });
  }

  gotoUser(client: LiveUser) {
    if (this.isConnected) {
      this.liveManager.gotoUser(client);
    }
  }
}
