import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { map } from 'rxjs/operators';

import { PlaygroundStore, ProjectStore } from '@ng-run/playground-store';

@Component({
  selector: 'ng-run-editor-header',
  templateUrl: './editor-header.component.html',
  styleUrls: ['./editor-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorHeaderComponent {
  @Input() openedFile;

  @Input() mode: 'html' | 'typescript';

  @Output() formatCodeClicked = new EventEmitter();

  isScreenShotMode$ = this.playgroundStore.screenShotMode$.pipe(map(mode => this.mode && mode === this.mode));

  selectedFile$ = this.projectStore.select(state => state.selectedFile);

  isDirty$ = this.projectStore.select(state => state.files[this.openedFile] && state.files[this.openedFile].dirty);

  prettierConfig$ = this.playgroundStore.prettierConfig$;

  constructor(private projectStore: ProjectStore, private playgroundStore: PlaygroundStore) {}

  setCurrentFile() {
    if (this.projectStore.state.selectedFile !== this.openedFile) {
      this.projectStore.selectFile(this.openedFile);
    }
  }

  makeScreenShot() {
    this.playgroundStore.toggleScreenShotMode(this.mode);
  }

  formatCode() {
    this.formatCodeClicked.emit();
  }

  updatePrettierConfig(value) {
    this.playgroundStore.updatePrettierConfig(value);
  }
}
