import { ComponentFactoryResolver, ComponentRef, Injectable, Injector, Type, ViewContainerRef } from '@angular/core';

type Props<T> = { [key in keyof T]: any };
type InputProps<T> = Partial<Props<T>>;

@Injectable({
  providedIn: 'root',
})
export class ContainerRegistry {
  private containers = new Map<string, ViewContainerRef>();
  private componentRefs = new Map<string, ComponentRef<any>>();

  constructor(private resolver: ComponentFactoryResolver, private injector: Injector) {}

  registerContainer(name: string, containerRef: ViewContainerRef) {
    this.containers.set(name, containerRef);
  }

  putComponentToContainer<T>(component: Type<T>, container: string, inputs: InputProps<T> = {}) {
    if (!this.containers.has(container)) {
      return;
    }

    const factory = this.resolver.resolveComponentFactory(component);
    const componentRef = this.containers.get(container).createComponent(factory, null, this.injector);
    for (const prop in inputs) {
      if (inputs.hasOwnProperty(prop)) {
        componentRef.instance[prop] = inputs[prop];
      }
    }
    componentRef.changeDetectorRef.detectChanges();
    this.componentRefs.set(container, componentRef);
  }

  destroyComponentFromContainer(container: string) {
    if (this.componentRefs.has(container)) {
      this.componentRefs.get(container).destroy();
      this.componentRefs.delete(container);
    }
  }
}
