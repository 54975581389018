import { PluginConsumer } from '../../plugin-consumer';

export function plugIn(editor: monaco.editor.IStandaloneCodeEditor, self: PluginConsumer) {
  editor.addCommand(monaco.KeyCode.F2, () => {
    monaco.languages.typescript.getTypeScriptWorker().then(worker => {
      const model = editor.getModel();

      worker(model.uri).then(workerInstance => {
        const promises = [];

        promises.push(workerInstance.getSyntacticDiagnostics(model.uri.toString()));
        promises.push(workerInstance.getSemanticDiagnostics(model.uri.toString()));

        Promise.all(promises).then(res => {
          for (let i = 0; i < res.length; i++) {
            for (let k = 0; k < res[i].length; k++) {
              const position = model.getPositionAt(res[i][k].start);
              editor.setPosition(position);
              editor.revealLineInCenter(position.lineNumber);
              return;
            }
          }
        });
      });
    });
  });
}
