import { ChangeDetectionStrategy, Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

import { AuthService } from '@ng-run/auth';
import { NotificationService } from '@ng-run/shared';
import { PlaygroundStore, ProjectStore } from '@ng-run/playground-store';
import { EditorWorker } from '../editor/editor.worker';
import { LiveService } from '../editor/live/live.service';

@Component({
  selector: 'ng-run-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  menuOpened$ = new BehaviorSubject(false);

  loading$ = new BehaviorSubject(false);

  canEdit$ = this.projectStore.canEdit$;

  isProjectDirty$ = this.projectStore.isProjectDirty$;

  isLesson$ = this.projectStore.isLesson$;

  isLessonRecording$ = this.projectStore.isLessonRecording$;

  authenticated$ = this.authService.authenticated$;

  isTestsSupported$ = this.projectStore.isTestsSupported$;

  testEnabled$ = this.playgroundStore.testEnabled$;
  recordState$ = new BehaviorSubject('init');

  constructor(
    private projectStore: ProjectStore,
    private editorWorker: EditorWorker,
    private authService: AuthService,
    private playgroundStore: PlaygroundStore,
    private notificationService: NotificationService,
    private location: Location,
    private elRef: ElementRef,
    public liveService: LiveService
  ) {}

  ngOnInit() {
    this.projectStore.aotEnabled$.subscribe(aot => {
      this.elRef.nativeElement.classList.toggle('header--aot', aot);
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(e) {
    /*const user = this.authService.user;
    if (!user) {
      return;
    }*/
    const { dirty, authorId } = this.projectStore.state;
    const canEdit = true; // user.uid === authorId;
    if (canEdit && dirty) {
      const dialogText =
        'You have unsaved changes on this page. Do you want to leave this page and discard your changes or stay on this page?';
      e.returnValue = dialogText;
      return dialogText;
    }
  }

  toggleMenu(close?: boolean) {
    const newValue = close !== undefined ? close : !this.menuOpened$.value;

    if (this.menuOpened$.value !== newValue) {
      this.menuOpened$.next(newValue);
      document.body.classList.toggle('menu--opened', this.menuOpened$.value);
    }
  }

  saveOrForkProject(forked = false) {
    this.loading$.next(true);
    this.editorWorker.doSave();
    this.projectStore
      .save(forked)
      .then(projectId => {
        this.notificationService.notify({
          type: 'success',
          message: 'Project has been successfully ' + (forked ? 'forked' : 'updated'),
        });
        if (forked) {
          this.location.go('/edit/' + projectId);
        }
      })
      .catch(err => {
        this.notificationService.notify({
          type: 'error',
          message: err.message,
        });
      })
      .finally(() => this.loading$.next(false));
  }

  toggleTest() {
    this.playgroundStore.toggleTestMode();
    setTimeout(() => {
      location.reload();
    }, 400);
  }

  toggleLive() {
    this.playgroundStore.setReloadMode('edit');
    this.liveService.toggleLive();
  }

  gotoUser(client: any) {
    this.liveService.gotoUser(client);
  }

  toggleActive() {
    this.recordState$.next(this.recordState$.value === 'active' ? 'init' : 'active');
  }
}
