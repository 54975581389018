import { ChangeDetectionStrategy, Component } from '@angular/core';
import { map } from 'rxjs/operators';

import { PlaygroundStore } from '@ng-run/playground-store';

@Component({
  selector: 'ng-run-theme-picker',
  templateUrl: './theme-picker.component.html',
  styleUrls: ['./theme-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThemePickerComponent {
  themes = [
    {
      key: 'atom',
      label: 'Atom one dark',
    },
    {
      key: 'dark',
      label: 'Dark+',
    },
    {
      key: 'dracula',
      label: 'Dracula',
    },
    {
      key: 'vstudio',
      label: 'Light (Visual Studio)',
    },
    {
      key: 'light',
      label: 'Light+',
    },
    {
      key: 'material',
      label: 'Material',
    },
    {
      key: 'monokai',
      label: 'Monokai',
    },
    {
      key: 'monokaiPro',
      label: 'Monokai Pro (Filter Ristretto)',
    },
    {
      key: 'night-owl',
      label: 'Night Owl',
    },
    {
      key: 'nord',
      label: 'Nord',
    },
    {
      key: 'northemDark',
      label: 'Northem Dark',
    },
    {
      key: 'oceanicNext',
      label: 'Oceanic Next',
    },
    {
      key: 'winter-is-coming',
      label: 'Winter is coming',
    },
  ];

  activeTheme$ = this.playgroundStore.activeTheme$.pipe(map(key => this.themes.find(theme => theme.key === key)));

  constructor(private playgroundStore: PlaygroundStore) {}

  applyTheme(theme) {
    this.playgroundStore.applyTheme(theme.key);
  }
}
