import { Component, ElementRef, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ProjectStore } from '@ng-run/playground-store';

@Component({
  selector: 'ng-run-solution-title',
  templateUrl: './solution-title.component.html',
  styleUrls: ['./solution-title.component.scss'],
})
export class SolutionTitleComponent {
  @ViewChild('editTitleControl') editTitleControl: ElementRef;

  project$ = this.projectStore.state$;

  canEdit$ = this.projectStore.canEdit$;

  error$ = new BehaviorSubject(null);

  editMode$ = new BehaviorSubject(false);

  settingsOpened$ = new BehaviorSubject(false);

  constructor(private projectStore: ProjectStore) {}

  editTitle() {
    this.error$.next('');
    this.editMode$.next(true);

    this.editTitleControl.nativeElement.focus();
    this.editTitleControl.nativeElement.setSelectionRange(0, this.editTitleControl.nativeElement.value.length);
  }

  updateTitle() {
    const value = this.editTitleControl.nativeElement.value.trim();
    const error = this.validate(value);

    if (error) {
      this.error$.next(error);
      return;
    }

    this.editMode$.next(false);

    this.projectStore.setTitle(value);
  }

  private validate(value: string) {
    if (!value) {
      return `Please enter title.`;
    }

    const msg = 'Please consider another title.';

    if (!/[a-z0-9\-]+$/i.test(value)) {
      return `Sorry, only alphanumeric values and '-' are allowed! ` + msg;
    }

    if (value.length > 100) {
      return 'Please enter a Title that is less than 100 characters long';
    }
  }
}
