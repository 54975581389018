import { ChangeDetectionStrategy, Component, HostListener } from '@angular/core';

import { PlaygroundStore } from '@ng-run/playground-store';

@Component({
  selector: 'ng-run-layout-picker',
  templateUrl: './layout-picker.component.html',
  styleUrls: ['./layout-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutPickerComponent {
  layout$ = this.playgroundStore.layout$;

  constructor(private playgroundStore: PlaygroundStore) {}

  @HostListener('click')
  toggle() {
    this.playgroundStore.toggleLayout();
  }
}
