import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ɵdetectChanges as detectChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

import { FileItem, FileTypeEnum, PlaygroundStore, ProjectStore } from '@ng-run/playground-store';
import { MonacoEditorStore } from '../../../editor/monaco.store';
import { EditorWorker } from '../../../editor/editor.worker';

@Component({
  selector: 'ng-run-solution-tree-item',
  templateUrl: './solution-tree-item.component.html',
  styleUrls: ['./solution-tree-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SolutionTreeItemComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() item: FileItem;

  @ViewChild('newItemControl') newItemControl: ElementRef;

  @ViewChild('dropdown') dropdown: any;

  opened = false;

  extension: string;

  @HostBinding('class')
  get className() {
    return `solution-tree-item ${this.item.type}${this.isSelected ? ' selected' : ''}${this.opened ? ' opened' : ''}`;
  }

  newItem: boolean;

  editMode: boolean;

  isFolder: boolean;

  errorElem: HTMLElement;

  isFailed$ = this.editorWorker.errorInfo$.pipe(
    map((err) => !!(err.file && err.file === this.item.fullPath)),
    distinctUntilChanged()
  );

  isSelected: boolean;

  canDelete: boolean;

  actionsOpened$ = new BehaviorSubject(false);

  supportedExtensions = ['css', 'scss', 'ts', 'html'];

  isDirty$ = this.projectStore.select((state) => state.files[this.item.fullPath].dirty);

  private destroyed$ = new Subject();

  @HostListener('mousedown', ['$event'])
  onClick(event) {
    if (this.editMode || this.newItem || event.which === 3) {
      return;
    }
    if (this.item.type !== FileTypeEnum.Folder) {
      this.projectStore.selectFile(this.item.fullPath);
      this.cdRef.markForCheck();
      return;
    }

    this.opened = !this.opened;
    this.cdRef.markForCheck();
  }

  constructor(
    private playgroundStore: PlaygroundStore,
    private projectStore: ProjectStore,
    private editorWorker: EditorWorker,
    private monacoStore: MonacoEditorStore,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.canDelete = ['main.ts', 'index.html', 'styles.css', 'app', 'index.ts'].indexOf(this.item.fullPath) === -1;

    this.isFolder = this.item.type === FileTypeEnum.Folder;
    if (!this.isFolder && this.item.name) {
      this.extension = this.item.name.substr(this.item.name.lastIndexOf('.') + 1);
    }

    this.newItem = this.item.name === '';
    const selectedFile = this.projectStore.state.selectedFile;

    if (this.isFolder && selectedFile.indexOf(this.item.fullPath) === 0) {
      this.opened = true;
    }

    this.projectStore
      .select((state) => state.selectedFile)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((sf) => {
        const selected = this.item.fullPath === sf;
        const opened = this.isFolder && sf.indexOf(this.item.fullPath) === 0;

        if (this.isSelected !== selected || this.opened !== opened) {
          this.isSelected = selected;
          this.opened = opened || this.opened;
          this.cdRef.markForCheck();
        }
      });
  }

  ngAfterViewInit() {
    if (this.newItem) {
      this.newItemControl.nativeElement.focus();
    }
  }

  openCliGenerator(command: string) {
    this.playgroundStore.useSchematic({ command, path: this.item.fullPath });
    this.actionsOpened$.next(false);
  }

  addItem(type: any) {
    this.opened = true;
    this.projectStore.addItem({
      key: this.item.fullPath + '/',
      value: {
        name: '',
        type: type,
        contents: '',
      },
    });
    detectChanges(this);
  }

  editItem() {
    this.editMode = true;
    detectChanges(this);

    this.newItemControl.nativeElement.focus();
    this.newItemControl.nativeElement.setSelectionRange(0, this.newItemControl.nativeElement.value.length);
  }

  updateItem(target: HTMLInputElement) {
    const value = this.newItemControl.nativeElement.value;
    const prevKey = this.item.fullPath.endsWith('/')
      ? this.item.fullPath
      : this.item.fullPath.substring(0, this.item.fullPath.length - this.item.name.length);

    const errorMsg = this.validate(value, prevKey, this.isFolder);

    if (errorMsg) {
      this.openError(errorMsg, target);
      return;
    }

    this.editMode = false;
    this.closeError();

    const changes = { add: {}, remove: [] };
    const files = this.projectStore.state.files;

    changes.remove.push(this.item.fullPath);
    this.monacoStore.deleteSolutionItem(this.item);

    if (this.isFolder) {
      Object.keys(files).forEach((key) => {
        if (key.indexOf(this.item.fullPath + '/') === 0) {
          changes.remove.push(key);
          this.monacoStore.deleteSolutionItem(Object.assign(files[key], { fullPath: key }));
          const updatedFile = { ...files[key] };
          updatedFile.fullPath = updatedFile.fullPath.replace(this.item.fullPath, prevKey + value);
          changes.add[updatedFile.fullPath] = updatedFile;
        }
      });
    }

    const newFile = this.item;
    const prevFullPath = this.item.fullPath;
    newFile.name = value;
    newFile.fullPath = prevKey + value;

    changes.add[newFile.fullPath] = newFile;

    this.monacoStore.addFiles(changes.add);

    this.projectStore.merge(changes);
    if (!this.newItem) {
      this.editorWorker.updateDecorators(
        Object.keys(changes.add).reduce((acc, cur) => {
          if (!cur.endsWith('.ts')) {
            return acc;
          }
          acc[cur] = changes.add[cur];
          return acc;
        }, {})
      );
    }

    let newSelectedFile = newFile.fullPath;
    if (this.isFolder) {
      const prevSelectedFile = this.projectStore.state.selectedFile;
      if (prevSelectedFile.indexOf(prevFullPath + '/') === 0) {
        newSelectedFile = prevSelectedFile.replace(prevFullPath, newFile.fullPath);
      }
    }

    this.projectStore.selectFile(newSelectedFile);

    this.editorWorker.change(changes);
  }

  deleteItem() {
    if (this.editMode) {
      this.editMode = false;
      this.closeError();
      detectChanges(this);
      return;
    }

    const result =
      !!this.newItem ||
      confirm(`Are you sure you want to delete ${this.item.fullPath} ${this.isFolder ? 'folder' : 'file'}?`);
    if (result) {
      const changes = { add: {}, remove: [] };
      const files = this.projectStore.state.files;

      if (this.isFolder) {
        Object.keys(files).forEach((key) => {
          if (key.indexOf(this.item.fullPath + '/') === 0) {
            changes.remove.push(key);
            this.monacoStore.deleteSolutionItem(Object.assign(files[key], { fullPath: key }));
          }
        });
      }

      changes.remove.push(this.item.fullPath);

      this.editorWorker.change(changes);

      this.projectStore.deleteItem(this.item);
      this.monacoStore.deleteSolutionItem(this.item);
      if (
        (!this.isFolder && this.item.fullPath === this.projectStore.state.selectedFile) ||
        (this.isFolder && this.projectStore.state.selectedFile.startsWith(this.item.fullPath))
      ) {
        this.projectStore.selectFile(this.projectStore.state.selectedFile.endsWith('.html') ? 'index.html' : 'main.ts');
      }
    }
  }

  ngOnDestroy() {
    this.closeError();
    this.destroyed$.next();
  }

  private openError(text: string, target: HTMLInputElement) {
    const elem = document.createElement('div');
    elem.className = 'solution-tree-item__error';
    elem.textContent = text;

    const { top, left } = target.getBoundingClientRect();
    elem.style.top = top + 'px';
    elem.style.left = left + target.clientWidth + 'px';
    document.body.appendChild(elem);
    this.errorElem = elem;
    detectChanges(this);
  }

  private closeError() {
    if (this.errorElem) {
      document.body.removeChild(this.errorElem);
      this.errorElem = null;
      detectChanges(this);
    }
  }

  private validate(value: string, prevKey: string, isFolder: boolean) {
    if (!value || !value.trim()) {
      return `Please enter name.`;
    }

    const msg = 'Please consider another name.';
    const files = this.projectStore.state.files;
    if (files[prevKey + value]) {
      return `There is already file with such name! ` + msg;
    }
    if (!/[a-z0-9\-]+$/i.test(value)) {
      return `Sorry, only alphanumeric values and '-' are allowed! ` + msg;
    }

    if (isFolder && value.indexOf('.') > -1) {
      return `Sorry, using '.' character is forbidden for folders. ` + msg;
    }

    if (!isFolder && !this.supportedExtensions.includes(value.split('.').pop())) {
      return `Sorry, we only support ${this.supportedExtensions.join(', ')} extensions. ` + msg;
    }
  }
}
