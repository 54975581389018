<div class="h-100 overflow-y-auto sidebar">
  <div class="close-menu" (click)="closeMenu.emit()">
    <svg viewBox="0, 0, 500, 500" width="40" height="40" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M250.696,82 L91,138.2 L116.235,347.426 L250.864,421.421 L386.186,346.424 L411.413,137.205 L250.696,82"
        fill="#FFFFFF"
      ></path>
      <path
        d="M395.484,149.298 L250.323,99.795 L250.323,403.742 L371.974,336.416 L395.484,149.298"
        fill="#a80e00"
      ></path>
      <path d="M108.92,150.189 L130.544,337.309 L250.32,403.742 L250.32,99.789 L108.92,150.189" fill="#e01600"></path>
      <path d="M210,180 L210,300 L310,240 z" fill="#FFFFFF"></path>
    </svg>
    <h1>Angular demo</h1>
  </div>
  <ng-container *ngIf="projectLoaded$ | async; else loadingTmpl">
    <ng-run-solution-title></ng-run-solution-title>
    <ng-run-toggler [title]="'Files'">
      <div *ngIf="nonNg$ | async" class="icon d-flex ml-auto folder-actions" (click)="$event.stopPropagation()">
        <div class="icon-add-file" title="New File" (click)="addItem('file')">
          <svg><use href="#icon-add-file"></use></svg>
        </div>
        <div class="icon-add-folder" title="New Folder" (click)="addItem('folder')">
          <svg><use href="#icon-add-folder"></use></svg>
        </div>
      </div>

      <ng-run-solution-explorer class="mb-2"></ng-run-solution-explorer>
      <ng-container *ngIf="author$ | async as author">
        <h3 class="mb-2 px-2 author-title">Author</h3>
        <div class="flex-y-center px-2 author">
          <img
            *ngIf="author.photoURL"
            class="author-image mr-1"
            width="30"
            [src]="author.photoURL"
            [alt]="author.displayName"
          />
          {{ author.displayName }}
        </div>
      </ng-container>
    </ng-run-toggler>
    <ng-run-toggler [title]="'Dependencies'">
      <div
        class="icon ml-auto icon-update-link trigger"
        title="Update all to latest"
        (click)="updateAllDeps(); $event.stopPropagation()"
      >
        <svg class="icon" width="13" height="14">
          <use xlink:href="#icon-update"></use>
        </svg>
      </div>
      <div class="dependencies p-2">
        <div *ngFor="let dep of dependencies$ | async; trackBy: trackByFn" class="dependency-item">
          <div class="dependency-name">{{ dep.name }}</div>
          <ng-run-dependency-resolver
            class="dependency-resolver"
            [depName]="dep.name"
            [version]="dep.version"
          ></ng-run-dependency-resolver>
        </div>
        <div class="install-dep">
          <input
            #newDepName
            class="install-dep-input"
            (keyup.enter)="installDep(newDepName)"
            placeholder="type package to install"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false"
          />
        </div>
      </div>
    </ng-run-toggler>
    <ng-run-toggler [title]="'External resources'" class="external-deps">
      <ng-run-external-resources class="p-2"></ng-run-external-resources>
    </ng-run-toggler>
  </ng-container>
  <ng-template #loadingTmpl>
    <ng-run-toggler [title]="'Files'">
      <div class="flex-center p-2 bg">
        <div class="loader"></div>
      </div>
    </ng-run-toggler>
  </ng-template>
  <ng-run-toggler *ngIf="authenticated$ | async" [title]="'Latest projects'">
    <ng-run-latest-projects></ng-run-latest-projects>
  </ng-run-toggler>
</div>

<a href="/about" class="about-project">About project</a>
