import { ChangeDetectionStrategy, Component, EventEmitter, Output, QueryList, ViewChildren } from '@angular/core';

import { AuthService } from '@ng-run/auth';
import { PlaygroundStore, ProjectStore } from '@ng-run/playground-store';
import { DependencyResolverComponent } from './dependency-resolver/dependency-resolver.component';
import { MonacoEditorStore } from '../editor/monaco.store';
import { EditorWorker } from '../editor/editor.worker';

@Component({
  selector: 'ng-run-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent {
  @Output() closeMenu = new EventEmitter();

  @ViewChildren(DependencyResolverComponent) resolvers: QueryList<DependencyResolverComponent>;

  projectLoaded$ = this.projectStore.projectLoaded$;

  author$ = this.projectStore.author$;

  nonNg$ = this.projectStore.nonNg$;

  authenticated$ = this.auth.authenticated$;

  dependencies$ = this.projectStore.dependencies$;

  constructor(
    private projectStore: ProjectStore,
    private auth: AuthService,
    private playgroundStore: PlaygroundStore,
    private monacoStore: MonacoEditorStore,
    private editorWorker: EditorWorker
  ) {}

  trackByFn(i, item) {
    return item.name;
  }

  updateAllDeps() {
    const packagesToUpdate = [];
    const deps = this.projectStore.state.dependencies;

    this.resolvers.forEach((resolver) => {
      const newVersion = resolver.versions[0];
      if (deps[resolver.depName].version === newVersion) {
        return;
      }

      const payload = {
        name: resolver.depName,
        version: newVersion,
        prevVersion: resolver.version,
      };

      packagesToUpdate.push(payload);

      this.monacoStore.clearExportsFor(resolver.depName);
      this.monacoStore.disposeExtraLibsForPackage(resolver.depName);
      this.projectStore.updateDep(payload);
    });

    if (!packagesToUpdate.length) {
      return;
    }

    this.editorWorker.installState$.next(packagesToUpdate.map((pkg) => `${pkg.name}@${pkg.version}`));
    this.editorWorker.post({
      type: 'UPDATE_DEPS',
      payload: {
        aot: this.projectStore.state.aot ? this.projectStore.getAotVersion() : false,
        deps: packagesToUpdate,
      },
    });
  }

  installDep(newDepNameEl: HTMLInputElement) {
    if (!newDepNameEl.value.length) {
      return;
    }
    this.editorWorker.install([newDepNameEl.value]);
    newDepNameEl.value = '';
  }

  addItem(type: string) {
    this.projectStore.addItem({
      key: '',
      value: {
        name: '',
        type,
        contents: '',
      },
    });
  }
}
