<ng-container *ngIf="{ projectLoaded: projectLoaded$ | async } as projectLoadedModel">
  <ng-run-editor-header
    *ngIf="projectLoadedModel.projectLoaded"
    [openedFile]="openedFile$ | async"
    [mode]="mode"
    (formatCodeClicked)="formatCode()"
  ></ng-run-editor-header>

  <div #editorBody class="editor-body">
    <ng-run-loading *ngIf="(loading$ | async) || !projectLoadedModel.projectLoaded"></ng-run-loading>
    <div class="h-100" #editorTarget></div>
  </div>

  <div class="status-bar">
    <div class="status-bar-line flex-1 flex-y-center"></div>
    <div class="d-flex no-wrap ml-3">
      <div>Typescript</div>
      <div class="ml-2">4.0.5</div>
      <ng-run-dropdown-menu class="ml-2" align="top" #menu>
        <ng-container menuTrigger
          >tsconfig.json <ng-run-icon class="icon-edit ml-2" name="pencil"></ng-run-icon
        ></ng-container>
        <div class="tsconfig-options-menu">
          <div class="mb-1"><b>Compiler options</b></div>
          <ul>
            <li *ngFor="let option of compilerOptions$ | async" class="mb-1">
              <label class="flex-y-center">
                <input
                  type="checkbox"
                  [checked]="option.checked"
                  (change)="option.checked = $any($event.target).checked; updateCompilerOptions()"
                />
                {{ option.key }}
              </label>
            </li>
            <li class="mb-1">
              <label class="flex-y-center">
                <b>Target</b>
                <select class="ml-auto" [value]="target$ | async" (change)="setTarget($event); menu.close()">
                  <option value="es5">es5</option>
                  <option value="es2015">es2015</option>
                </select>
              </label>
            </li>
          </ul>
        </div>
      </ng-run-dropdown-menu>
    </div>
  </div>
</ng-container>
