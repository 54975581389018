import { Component } from '@angular/core';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'ng-run-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent {
  constructor() {
    AsyncPipe.prototype['_updateLatestValue'] = function(async, value) {
      if (async === this._obj) {
        this._latestValue = value;
        this._ref.detectChanges();
      }
    };
  }
}
