import { Injectable } from '@angular/core';
import { Location } from '@angular/common';

import { Store } from '@ng-run/store';
import { getParameterByName, updateQueryStringParameter } from '@ng-run/utils';
import { LayoutType, PlaygroundState, ReloadMode, SchematicOptions } from './playground.state';

const defaultPrettierConfig = {
  useTabs: false,
  printWidth: 120,
  tabWidth: 2,
  singleQuote: true,
  trailingComma: 'es5',
  semi: true,
};

@Injectable({
  providedIn: 'root',
})
export class PlaygroundStore extends Store<PlaygroundState> {
  testEnabled$ = this.select(state => state.testMode === 'true');

  activeTheme$ = this.select(state => state.theme);

  screenShotMode$ = this.select(state => state.screenShotMode);

  reloadMode$ = this.select(state => state.reloadMode);

  schematicOptions$ = this.select(state => state.schematicOptions);

  prettierConfig$ = this.select(state => state.prettierConfig);

  layout$ = this.select(state => state.layout);

  previewUrl$ = this.select(state => state.previewUrl);

  constructor(private location: Location) {
    super({
      layout: getParameterByName('layout') || localStorage.getItem('layout') || (LayoutType.One as any),
      testMode: getParameterByName('test') || 'false',
      prettierConfig: JSON.parse(localStorage.getItem('prettier')) || defaultPrettierConfig,
      previewUrl: '',
      reloadMode: (localStorage.getItem('reloadMode') || 'edit') as ReloadMode,
    });

    this.applyTheme(localStorage.getItem('monaco-theme') || 'material');
  }

  toggleLayout() {
    const layout = this.state.layout === LayoutType.One ? LayoutType.Two : LayoutType.One;
    this.location.replaceState(updateQueryStringParameter('layout', layout));
    localStorage.setItem('layout', layout);
    this.setState({
      layout,
    });
  }

  toggleTestMode() {
    const testMode = this.state.testMode === 'true' ? 'false' : 'true';
    this.location.replaceState(updateQueryStringParameter('test', testMode));
    this.setState({
      testMode,
    });
  }

  applyTheme(theme: string) {
    document.body.classList.remove(this.state.theme);
    document.body.classList.add(theme);
    if (window['monaco'] && monaco.editor) {
      monaco.editor.setTheme(['light', 'vstudio'].includes(theme) ? 'vs-light' : 'vs-dark');
    }
    localStorage.setItem('monaco-theme', theme);
    this.setState({
      theme,
    });
  }

  toggleScreenShotMode(type: 'html' | 'typescript') {
    const mode = this.state.screenShotMode;
    this.setState({
      screenShotMode: mode === type ? undefined : type,
    });
  }

  useSchematic(schematicOptions?: SchematicOptions) {
    this.setState({
      schematicOptions,
    });
  }

  updatePrettierConfig(value: string) {
    try {
      const prettierConfig = JSON.parse(value);
      localStorage.setItem('prettier', JSON.stringify(prettierConfig));
      this.setState({
        prettierConfig,
      });
    } catch (e) {}
  }

  changePreviewUrl(previewUrl: string) {
    this.setState({
      previewUrl,
    });
  }

  setReloadMode(reloadMode: ReloadMode) {
    localStorage.setItem('reloadMode', reloadMode);
    this.setState({
      reloadMode,
    });
  }
}
