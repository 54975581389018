import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';

import { PlaygroundStore, ProjectStore } from '@ng-run/playground-store';
import { EditorWorker } from '../../editor/editor.worker';
import { MonacoEditorStore } from '../../editor/monaco.store';

@Component({
  selector: 'ng-run-cli-generator',
  templateUrl: './cli-generator.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CliGeneratorComponent {
  schematic$ = this.playgroundStore.schematicOptions$;

  @ViewChild('cliInput')
  set cliInput(inputEl: ElementRef<HTMLInputElement>) {
    if (inputEl) {
      inputEl.nativeElement.focus();
    }
  }

  constructor(
    private editorWorker: EditorWorker,
    private playgroundStore: PlaygroundStore,
    private projectStore: ProjectStore,
    private monacoStore: MonacoEditorStore
  ) {}

  closeWindow() {
    this.playgroundStore.useSchematic();
  }

  runSchematics(name: string) {
    if (!name) {
      return;
    }
    const { command, path } = this.playgroundStore.state.schematicOptions;

    this.playgroundStore.useSchematic();

    this.editorWorker.worker
      .postMessage({
        type: 'RUN_SCHEMATIC',
        payload: {
          command: command.toLowerCase(),
          name,
          path,
          files: this.projectStore.state.files,
        },
      })
      .then(({ changes, selected, newFiles }) => {
        const workerChanges = { add: {}, remove: [] };
        Object.keys(newFiles).forEach(key => {
          workerChanges.add[newFiles[key].fullPath] = newFiles[key];
          if (newFiles[key].type !== 'folder') {
            this.monacoStore.addModel(key, newFiles[key].contents);
          }
          this.projectStore.addItem({
            key: key,
            value: newFiles[key],
          });
          this.projectStore.selectFile(selected);
        });

        const appliedChanges = this.applyChanges(changes);
        appliedChanges.forEach(change => {
          workerChanges.add[change.fullPath] = {
            type: 'file',
            contents: change.contents,
          };
          newFiles[change.fullPath] = {
            contents: change.contents,
          };
        });
        this.editorWorker.updateDecorators(newFiles);

        this.editorWorker.change(workerChanges);
      });
  }

  applyChanges(changes) {
    const files = this.projectStore.state.files;
    return changes.map(change => {
      const fileToChange = files[change.file];
      if (fileToChange) {
        const text = fileToChange.contents;
        const newContent = text.substr(0, change.position) + change.toInsert + text.substr(change.position);
        this.monacoStore.updateModel(fileToChange.fullPath, newContent);
        fileToChange.contents = newContent;
        return { fullPath: fileToChange.fullPath, contents: newContent };
      }
    });
  }
}
