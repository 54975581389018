import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'ng-run-toggler',
  templateUrl: './toggler.component.html',
  styleUrls: ['./toggler.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TogglerComponent {
  @Input() title: string;

  opened$ = new BehaviorSubject(true);
}
