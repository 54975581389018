import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  // tslint:disable-next-line
  selector: '[split-area]',
  exportAs: 'splitArea',
  // tslint:disable-next-line:no-host-metadata-property
  host: {
    class: 'split-area',
  },
})
export class SplitAreaDirective {
  @Input('split-area') size = 0;

  private _hidden = false;

  set hidden(value: boolean) {
    this._hidden = value;
    this.elRef.nativeElement.classList.toggle('d-none', value);
  }

  get hidden() {
    return this._hidden;
  }

  constructor(public elRef: ElementRef<HTMLElement>) {}
}
