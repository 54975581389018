import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  ElementRef,
  Input,
  OnDestroy,
  QueryList,
} from '@angular/core';
import { SplitAreaDirective } from './split-area.directive';
import { BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

const isTouch = 'ontouchstart' in window;

const DragEvent = {
  MOVE: isTouch ? 'touchmove' : 'mousemove',
  RELEASE: isTouch ? 'touchend' : 'mouseup',
};

@Component({
  // tslint:disable-next-line
  selector: '[splitter]',
  templateUrl: './splitter.component.html',
  styleUrls: ['./splitter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SplitterComponent implements AfterContentInit, OnDestroy {
  // tslint:disable-next-line
  @Input('splitterDirection') direction: 'horizontal' | 'vertical' = 'horizontal';

  @ContentChildren(SplitAreaDirective) areaDirs: QueryList<SplitAreaDirective>;

  areas: SplitAreaDirective[];

  areas$ = new BehaviorSubject([]);

  separatorSize = 16;

  private positions: number[];

  private destroyed$ = new Subject();

  private resizeProp: string;

  get containerSize() {
    return this.elRef.nativeElement.getBoundingClientRect()[this.resizeProp];
  }

  get separatorsSize() {
    return this.separatorSize * (this.visibleAreas.length - 1);
  }

  get visibleAreas() {
    return this.areas.filter(area => !area.hidden);
  }

  constructor(private elRef: ElementRef<HTMLElement>) {}

  ngAfterContentInit() {
    this.resizeProp = this.direction === 'horizontal' ? 'width' : 'height';
    if (this.direction === 'vertical') {
      this.elRef.nativeElement.style.flexDirection = 'column';
    }

    this.areas = this.areaDirs.toArray();

    this.updateLayout();
  }

  updateLayout() {
    this.areas$.next(this.visibleAreas.map(dir => dir.size / 100));
    this.visibleAreas.forEach((area, i) => {
      area.elRef.nativeElement.style.order = (i * 2).toString();
    });

    this.updateAreasSize();
  }

  onSeparatorMouseDown(event: any, index: number) {
    const shiftProp = this.direction === 'horizontal' ? 'pageX' : 'pageY';
    const startPoint = isTouch ? event.touches[0][shiftProp] : event[shiftProp];
    this.positions = this.getPositions();
    const draggableArea = this.positions[index + 1];

    document.body.classList.add('dragging');

    const mouseUp$ = fromEvent(document, DragEvent.RELEASE).pipe(tap(() => document.body.classList.remove('dragging')));
    fromEvent(document, DragEvent.MOVE)
      .pipe(takeUntil(mouseUp$))
      .subscribe((e: any) => {
        const shift = (isTouch ? e.touches[0][shiftProp] : e[shiftProp]) - startPoint;
        let newSize = draggableArea + shift / (this.containerSize - this.separatorsSize);
        newSize = Math.max(0, Math.min(1, newSize));

        this.calculatePositions(index + 1, newSize);
      });
  }

  private calculatePositions(index: number, newSize: number) {
    this.positions[index] = newSize;
    for (let i = 0; i < this.positions.length; i++) {
      if (i < index) {
        this.positions[i] = Math.min(this.positions[i], newSize);
      }
      if (i > index) {
        this.positions[i] = Math.max(this.positions[i], newSize);
      }
    }

    const areas = this.areas$.value;
    this.positions.forEach((pos, i) => {
      const size = this.positions[i + 1];
      if (areas[i] !== undefined) {
        areas[i] = (size === undefined ? 1 : size) - pos;
      }
    });

    this.updateAreasSize();
  }

  private updateAreasSize() {
    const areas = this.areas$.value;

    const separatorsSpace = (this.separatorsSize * 100) / this.containerSize;
    const availablePercent = 100 - separatorsSpace;

    this.visibleAreas.forEach((area, i) => {
      const size = availablePercent * areas[i];

      area.size = areas[i] * 100;
      area.elRef.nativeElement.style[this.resizeProp] = `${size}%`;
    });
  }

  private getPositions() {
    return this.areas$.value.reduce((acc, curr, i) => [...acc, (i > 0 ? acc[i] : 0) + curr], [0]);
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }
}
