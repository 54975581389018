import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'ng-run-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent {
  message: string;

  @HostBinding('class') type: string;
}
