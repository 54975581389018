import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, Subject } from 'rxjs';

import { environment } from '@ng-run/env';
import { PlaygroundStore, ProjectStore } from '@ng-run/playground-store';
import { PromisedWorker } from '@ng-run/utils';
import { MonacoEditorStore } from './monaco.store';
import { transpileSass } from './sass.worker';
import { loadEditor } from './loader/editor.loader';

@Injectable({
  providedIn: 'root',
})
export class EditorWorker {
  errorInfo$ = new BehaviorSubject<any>({});

  passed$ = new Subject();

  confirmInstallState$ = new BehaviorSubject<string[]>(null);

  installState$ = new BehaviorSubject<string[]>(null);

  bundleSuccess$ = new BehaviorSubject<any>(false);

  inspect$ = new Subject<any>();

  decoratorsUpdated$ = new BehaviorSubject(false);

  initPassed;

  worker: PromisedWorker;

  resolvePreviewLoaded;

  wasLexerError = false;

  previewLoaded = new Promise((resolve) => {
    this.resolvePreviewLoaded = resolve;
  });

  private changesTimeout = {};

  private files = {};

  constructor(
    private projectStore: ProjectStore,
    private monacoStore: MonacoEditorStore,
    private playgroundStore: PlaygroundStore
  ) {
    this.worker = new PromisedWorker(new Worker(`/${environment.hash ? environment.hash + '.' : ''}app.worker.js`));

    this.worker
      .on('WORKER_CONNECTED', () => {
        this.projectStore.projectLoaded$.subscribe(() => {
          this.updateDecorators(this.projectStore.state.files);
          this.worker.postMessage({
            type: 'START',
            payload: {
              ...this.projectStore.state,
              testMode: this.playgroundStore.state.testMode,
              aot: this.projectStore.state.aot ? this.projectStore.getAotVersion() : false,
            },
          });
        });
      })
      .on('PASSED', (data) => {
        this.previewLoaded.then(() => {
          this.confirmInstallState$.next(null);
          const project = data.payload.project;
          const aot = this.projectStore.state.aot;

          if (data.payload.updated) {
            this.projectStore.updateDeps(project.dependencies);
            sendToPreview({
              type: 'UPDATE',
              payload: data.payload,
            });

            setTimeout(() => {
              this.installState$.next(null);
            }, 500);

            this.bundleSuccess$.next(project);
            if (aot) {
              monaco.languages.typescript.typescriptDefaults['refresh']();
            }
          }

          if (!aot) {
            if (data.payload.diff) {
              sendToPreview({
                type: 'DIFF',
                payload: data.payload.diff,
              });
            } else if (!data.payload.updated) {
              sendToPreview({
                type: 'START',
                payload: { ...project, aot },
              });
              this.bundleSuccess$.next(project);
            }
          } else if (!this.initPassed) {
            loadEditor().then(() => {
              this.bundleSuccess$.next(project);
              setTimeout(() => {
                this.runIvyAOT({}, project);
              });
            });
          } else if (data.payload.refreshed) {
            if (this.files) {
              project.files = this.files;
            }
            setTimeout(() => {
              this.runIvyAOT({}, project);
            });
          }

          this.passed$.next();
          if (!this.initPassed) {
            this.initPassed = true;
          }
        });
      })
      .on('FAILED', (data) => {
        if (!this.initPassed) {
          this.monacoStore.enableValidation();
        }
        this.installState$.next(null);
        this.confirmInstallState$.next(null);

        if (data.payload.type && data.payload.type === 'glob') {
          const pkgNames = getNormalizedPackageNames(data.payload.sources);
          const result = !this.initPassed || this.confirmInstall(pkgNames);

          if (result) {
            this.install(pkgNames);
            return;
          }
        }

        this.errorInfo$.next(data.payload);

        sendToPreview({
          type: 'FAILED',
          payload: data.payload,
        });
      })
      .on('COMPILE_SASS', (data) => {
        if (!data) {
          return Promise.resolve('');
        }
        return transpileSass(data).catch((err) => {
          sendToPreview({
            type: 'FAILED',
            payload: { message: err, file: '' },
          });
        });
      });

    this.bundleSuccess$.subscribe((project) => {
      if (!project) {
        return;
      }

      this.monacoStore.loadVendors(project);
    });
    this.handleWindowMessages();
  }

  handleWindowMessages() {
    fromEvent(window, 'message').subscribe((event: any) => {
      if (!event.data) {
        return;
      }

      switch (event.data.type) {
        case 'PREVIEW_ERROR': {
          this.errorInfo$.next(event.data.data);
          break;
        }
        case 'REFRESH': {
          this.files = event.data.data;
          break;
        }
        case 'FAILED': {
          const payload = event.data.data;
          if (payload.type === 'rel') {
            this.errorInfo$.next(payload);

            sendToPreview({
              type: 'FAILED',
              payload: payload,
            });
            break;
          }
          const pkgNames = getNormalizedPackageNames(payload.sources);
          this.confirmInstall(pkgNames);
          break;
        }
        case 'PREVIEW_CONNECTED': {
          if (this.initPassed) {
            this.post({
              type: 'REFRESH',
              payload: { ...this.projectStore.state, testMode: this.playgroundStore.state.testMode },
            });
          }

          this.resolvePreviewLoaded();
          break;
        }
        case 'URL_CHANGED': {
          this.playgroundStore.changePreviewUrl(event.data.payload);
          break;
        }
        case 'OPEN_FILE': {
          let { filePath, offset } = event.data.payload;
          let position;
          if (filePath.includes('@')) {
            const [newFile, pos] = filePath.split('@');
            filePath = newFile;
            const [shiftLine, shiftCol] = pos.split(':');
            const shiftLineNum = parseInt(shiftLine, 10);
            const shiftColNum = parseInt(shiftCol, 10) + 1;
            position = new monaco.Position(shiftLineNum, shiftColNum);
          }
          this.inspect$.next({ filePath, offset, position });
          break;
        }
        default:
      }
    });
  }

  updateDecorators(files) {
    this.worker
      .postMessage({
        type: 'PARSE',
        payload: { files: files },
      })
      .then((payload: any) => {
        this.decoratorsUpdated$.next(payload);

        const { exports, imports, templatePositions } = payload;
        Object.keys(exports).forEach((key) => {
          this.monacoStore.exports[key] = {
            type: 'local',
            set: exports[key],
          };
        });
        Object.keys(imports).forEach((key) => {
          this.monacoStore.imports[key] = imports[key];
        });
        Object.keys(templatePositions).forEach((key) => {
          this.monacoStore.templatePositions[key] = templatePositions[key];
        });
      });
  }

  detectChanges(selected, value) {
    const project = this.projectStore.state;
    const files = project.files;
    if (!files[selected] || files[selected].contents === value) {
      return;
    }

    this.setErrors({});

    files[selected].contents = value;
    this.projectStore.setDirty();

    if (selected.endsWith('.ts')) {
      this.updateDecorators({ [selected]: files[selected] });
    }

    if (this.playgroundStore.state.reloadMode === 'save') {
      if (!this.projectStore.state.files[selected].dirty) {
        this.projectStore.setDirtyOnFiles(true, [selected]);
      }
      return;
    }

    const change = {
      fullPath: selected,
      contents: value,
      lastModified: Date.now(),
    };
    const newChanges = {
      add: {
        [selected]: change,
      },
      remove: [],
    };

    if (this.changesTimeout[selected]) {
      clearTimeout(this.changesTimeout[selected]);
    }

    this.changesTimeout[selected] = setTimeout(() => {
      this.change(newChanges, project);

      delete this.changesTimeout[selected];
    }, 500);
  }

  change(newChanges, project = this.projectStore.state) {
    if (this.installState$.value) {
      return;
    }
    const action = {
      type: this.initPassed ? 'MERGE' : 'START',
      payload: this.initPassed ? newChanges : project,
    };

    if (this.projectStore.state.aot) {
      this.runIvyAOT(action);
    } else {
      this.post(action);
    }
  }

  post(message) {
    this.worker.postMessage(message);
  }

  setErrors(errors) {
    this.errorInfo$.next(errors);
  }

  confirmInstall(pkgNames: string[]) {
    this.installState$.next(null);
    this.confirmInstallState$.next(pkgNames);
  }

  install(pkgNames: string[]) {
    this.installState$.next(pkgNames);
    this.confirmInstallState$.next(null);

    this.worker.postMessage({
      type: 'INSTALL',
      payload: {
        packages: pkgNames,
        aotEnabled: this.projectStore.state.aot ? this.projectStore.getAotVersion() : false,
      },
    });
  }

  runIvyAOT(action, project?) {
    this.setErrors({});
    let t0;
    if (!environment.production) {
      t0 = performance.now();
      console.log('AOT started');
    }

    const ngVersion = this.projectStore.getAotVersion();
    this.confirmInstallState$.next(null);
    monaco.languages.typescript.getAotWorker(this.projectStore.getAotVersion()).then((worker) => {
      sendToPreview({
        type: 'COMPILE_START',
      });

      worker('').then((w) => {
        if (this.wasLexerError) {
          this.wasLexerError = false;
          Object.keys(this.projectStore.state.files).forEach((key) => {
            if (key.endsWith('.ts')) {
              action.payload.add[key] = this.projectStore.state.files[key];
            }
          });
        }
        w.ivy(action, ngVersion).then((res) => {
          if (project) {
            Object.keys(res.output).forEach((fileName) => {
              const fullPath = fileName.slice(4);
              if (!project.files[fullPath]) {
                project.files[fullPath] = {
                  fullPath,
                };
              }
              project.files[fullPath].contents = res.output[fileName];
            });
            sendToPreview({
              type: 'START',
              payload: { ...project, aot: ngVersion, failed: !!res.diagnostics },
            });
          } else if (!res.diagnostics) {
            const newChanges = {
              add: {},
              remove: [],
            };

            Object.keys(res.output).forEach((fileName) => {
              newChanges.add[fileName.slice(4)] = {
                fullPath: fileName.slice(4),
                contents: res.output[fileName],
              };
            });
            if (action.type === 'MERGE') {
              Object.keys(action.payload.add || {}).forEach((fileName) => {
                if (fileName.endsWith('.css')) {
                  newChanges.add[fileName] = action.payload.add[fileName];
                }
              });
            }
            sendToPreview({
              type: 'DIFF',
              payload: newChanges,
            });
          }

          if (res.diagnostics) {
            if (res.ngtscDiagnostics.length) {
              this.wasLexerError = res.ngtscDiagnostics.some((diag) => diag.message.includes('Lexer Error'));
            }
            sendToPreview({
              type: 'FAILED',
              payload: {
                message: res.diagnostics,
              },
            });
          }

          if (res.ngtscDiagnostics && res.ngtscDiagnostics.length) {
            const diagnostic = res.ngtscDiagnostics[0];
            const unImportedModules = res.ngtscDiagnostics
              .filter(({ code, category }) => code === 2307 && category === 1)
              .map(this.toUnresolvedModules)
              .filter(Boolean);
            if (unImportedModules.length) {
              const pkgNames = getNormalizedPackageNames(unImportedModules);
              const result = !this.initPassed || this.confirmInstall(pkgNames);

              if (result) {
                this.install(pkgNames);
              }
            } else {
              this.errorInfo$.next(diagnostic);
            }
          }

          if (!environment.production) {
            const t1 = performance.now();
            console.log('Ivy:AOT', t1 - t0);
          }
        });
      });
    });
  }

  toUnresolvedModules = (diag: NgRunDiagnostic) => {
    const file = this.projectStore.state.files[diag.file];
    if (file) {
      return file.contents.slice(diag.startPos + 1, diag.endPos - 1);
    }
  };

  doSave() {
    if (this.playgroundStore.state.reloadMode === 'edit') {
      return;
    }

    const { files } = this.projectStore.state;
    const dirtyFiles = Object.keys(files).filter((key) => files[key].dirty);
    const newChanges = {
      add: {},
      remove: [],
    };

    dirtyFiles.forEach((key) => {
      const file = files[key];
      newChanges.add[file.fullPath] = {
        fullPath: file.fullPath,
        contents: file.contents,
        lastModified: Date.now(),
      };
    });

    this.projectStore.setDirtyOnFiles(false);
    this.change(newChanges, this.projectStore.state);
  }
}

export function sendToPreview(action) {
  preview.postMessage(action, '*');
}

function getNormalizedPackageNames(sources: string[]) {
  return sources.map((x) => normalizePackageName(x)).filter(onlyUnique);
}

function normalizePackageName(pkgName: string) {
  if (pkgName.startsWith('@')) {
    const [scope, name] = pkgName.split('/');
    return scope + (name ? '/' + name : '');
  } else if (!pkgName.startsWith('.')) {
    const slashIndex = pkgName.indexOf('/');
    return pkgName.substring(0, slashIndex > 0 ? slashIndex : pkgName.length);
  } else {
    return pkgName;
  }
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}
