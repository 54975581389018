import { Component, Input, ChangeDetectionStrategy, OnChanges } from '@angular/core';

import { FileItem, FileTypeEnum } from '@ng-run/playground-store';

@Component({
  selector: 'ng-run-solution-tree',
  templateUrl: './solution-tree.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SolutionTreeComponent implements OnChanges {
  @Input() curItem: FileItem;

  @Input() files: FileItem[];

  items: FileItem[];

  ngOnChanges() {
    this.items = this.files
      .filter(this.curItem ? this.filter.bind(this) : this.rootFilter)
      .sort((a: FileItem, b: FileItem) => {
        if (a.type !== b.type) {
          return a.type === FileTypeEnum.Folder ? -1 : 1;
        }

        if (a.fullPath < b.fullPath) {
          return -1;
        }
        if (a.fullPath > b.fullPath) {
          return 1;
        }
        return 0;
      });
  }

  trackByFn(index, item) {
    return item.fullPath;
  }

  private rootFilter(file) {
    return file.fullPath.split('/').length === 1;
  }

  private filter(file) {
    if (file.fullPath.startsWith(this.curItem.fullPath)) {
      const itemParts = file.fullPath.split('/');
      const curItemParts = this.curItem.fullPath.split('/');
      return (
        itemParts.length === curItemParts.length + 1 &&
        curItemParts[curItemParts.length - 1] === itemParts[itemParts.length - 2]
      );
    }
  }
}
