<span class="solution-tree-item__name" [ngClass]="extension"
      [class.solution-tree-item--failed]="isFailed$ | async"
>
  <i *ngIf="isDirty$ | async" class="item-dirty"></i>
  <ng-container *ngIf="isFolder; else fileIconTmpl">
    <ng-run-icon [name]="opened ? 'opened-folder' : 'folder'" class="icon-folder"></ng-run-icon>
    <ng-run-icon
      *ngIf="!newItem && !editMode"
      [name]="'arrow-' + (opened ? 'down' : 'right')"
      class="icon-arrow"
    ></ng-run-icon>
  </ng-container>
  <ng-template #fileIconTmpl>
    <ng-run-icon
      class="icon-file"
      width="15"
      height="15"
      [name]="extension || ''"
      *ngIf="extension !== 'ts'"
    ></ng-run-icon>
  </ng-template>
  <ng-container *ngIf="newItem || editMode; else inline">
    <input
      #newItemControl
      class="solution-tree-item__input"
      [value]="editMode ? item.name : ''"
      (keyup.enter)="updateItem($any($event.target))"
      (keydown.escape)="deleteItem()"
      autocomplete="off"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="false"
    />
    <div class="solution-tree-item__blocker" (click)="deleteItem()"></div>
  </ng-container>
  <ng-template #inline>{{ item.name }}</ng-template>
</span>
<div
  *ngIf="!newItem && !editMode"
  class="solution-actions"
  [class.solution-actions--opened]="dropdown && (dropdown.opened | async)"
  (mousedown)="$event.stopPropagation()"
>
  <ng-container *ngIf="isFolder">
    <ng-run-dropdown-menu #dropdown align="left">
      <div menuTrigger class="cli-trigger" title="CLI Generator">
        <svg class="ng-icon"><use href="#angular"></use></svg>
      </div>
      <div>
        <div
          *ngFor="let item of ['Component', 'Directive', 'Module', 'Service', 'Pipe']"
          class="option-item"
          (click)="openCliGenerator(item); dropdown.close()"
        >
          {{ item }}
        </div>
      </div>
    </ng-run-dropdown-menu>

    <div class="icon-add-file" title="New File" (click)="addItem('file')">
      <svg><use href="#icon-add-file"></use></svg>
    </div>
    <div class="icon-add-folder" title="New Folder" (click)="addItem('folder')">
      <svg><use href="#icon-add-folder"></use></svg>
    </div>
  </ng-container>
  <svg class="icon-edit" *ngIf="canDelete" (click)="editItem()"><use xlink:href="#icon-pencil"></use></svg>
  <svg class="icon-delete" *ngIf="canDelete" (click)="deleteItem()"><use xlink:href="#icon-delete"></use></svg>
</div>
