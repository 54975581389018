<ng-run-header class="px-2" #header></ng-run-header>

<section class="main" splitter>
  <ng-run-sidebar [split-area]="areas[0]" class="sidebar-area" (closeMenu)="header.toggleMenu(false)"></ng-run-sidebar>
  <ng-run-editor [split-area]="areas[1]" mode="typescript"></ng-run-editor>
  <ng-run-editor [split-area]="areas[2]" mode="html"></ng-run-editor>
  <ng-run-preview [split-area]="areas[3]"></ng-run-preview>
  <div *ngIf="goToFileDialogOpened$ | async">
    <ng-run-go-to-file (closed)="toggleGoToFileDialog(false)"></ng-run-go-to-file>
    <div class="fake-bg" (click)="toggleGoToFileDialog(false)"></div>
  </div>
  <div *ngIf="searchDialogOpened$ | async">
    <ng-run-search-dialog></ng-run-search-dialog>
    <div class="fake-bg" (click)="toggleSearchDialog(false)"></div>
  </div>
</section>
<ng-run-cli-generator></ng-run-cli-generator>
<ng-template ng-run-container="player"></ng-template>
