<div class="flex-y-center h-100 login-btn trigger" *ngIf="!(user$ | async); else userTmpl" (click)="login()">
  <ng-run-icon class="mr-1" name="github" width="35" height="35"></ng-run-icon>
  Sign in
</div>

<ng-template #userTmpl>
  <ng-run-dropdown-menu *ngIf="user$ | async as user" [showArrow]="true" align="right">
    <ng-container menuTrigger>
      <div *ngIf="user.photoURL" class="user-photo">
        <img width="35" height="35" [src]="user.photoURL" [alt]="user.displayName" />
      </div>
      <div class="user-name" [class.ml-2]="!!user.photoURL">
        {{ user.displayName || user.email }}
      </div>
    </ng-container>
    <div class="menu-item" (click)="logout()">
      Sign out
    </div>
  </ng-run-dropdown-menu>
</ng-template>
