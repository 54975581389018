import { FileItem, FileTypeEnum, ProjectState } from './project.state';

export const WORKSPACES: Workspace = {
  ts: {
    title: 'Base TypeScript Project',
    selectedFile: 'index.ts',
    manifest: {
      indexHtml: 'index.html',
      entry: 'index.ts',
      styles: 'styles.css',
    },
    dependencies: {
      rxjs: {
        main: './index.js',
        typings: './index.d.ts',
        version: '6.6.0',
      },
    },
    files: {
      'index.html': {
        name: 'index.html',
        type: FileTypeEnum.File,
        contents: `<div id="app"></div>`,
      },
      'index.ts': {
        name: 'index.ts',
        type: FileTypeEnum.File,
        contents: `const app = document.getElementById('app');
app.textContent = 'Hello World!';`,
      },
      'styles.css': {
        name: 'styles.css',
        type: FileTypeEnum.File,
        contents: `/* Write your global styles here */`,
      },
    },
    nonNg: true,
  },
};

export interface Workspace {
  [key: string]: ProjectState;
}
