import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

import { PaginationService, ProjectService } from '@ng-run/services';
import { ProjectStore } from '@ng-run/playground-store';
import { timeSince } from '@ng-run/utils';

@Component({
  selector: 'ng-run-latest-projects',
  templateUrl: './latest-projects.component.html',
  styleUrls: ['./latest-projects.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LatestProjectsComponent {
  projects$ = this.projectService.getLastProjects().pipe(
    map((projects) => {
      const currentId = this.projectStore.state ? this.projectStore.state.id : '';
      return projects.map((data) => {
        const id = data.id;
        return {
          id,
          title: data.title,
          timeAgo: data.createdAt ? timeSince(data.createdAt) : '',
          current: id === currentId,
        };
      });
    }),
    finalize(() => {
      this.loading$.next(false);
    })
  );

  loading$ = new BehaviorSubject(true);

  opened$ = new BehaviorSubject(false);

  constructor(
    private projectStore: ProjectStore,
    private projectService: ProjectService,
    public page: PaginationService
  ) {}

  scrollHandler(e) {
    if (e === 'bottom') {
      this.page.more();
    }
  }

  openAllProjects() {
    this.page.reset();
    this.page.init('projects', 'createdAt', { reverse: true, prepend: false });

    this.opened$.next(!this.opened$.value);
  }

  close() {
    this.opened$.next(false);
  }
}
