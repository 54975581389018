<ng-container *ngIf="{ opened: opened$ | async } as vm">
  <h3 class="toggler" (click)="opened$.next(!opened$.value)">
    <ng-run-icon class="icon-arrow" [name]="'arrow-' + (vm.opened ? 'down' : 'right')"></ng-run-icon>
    <span class="toggler-title">{{ title }}</span>
    <ng-content select=".icon"></ng-content>
  </h3>
  <div [class.d-none]="!vm.opened">
    <ng-content></ng-content>
  </div>
</ng-container>
