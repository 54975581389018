<div class="layout-picker" [class.layout-picker--right]="(layout$ | async) === '1'" ng-run-tooltip="Layout Picker">
  <div class="layout-option">
    <svg width="20" height="18" viewBox="0 -1 16 16">
      <path d="M1 1v12h14V1H1zm1 3h12v8H2V4z"></path>
    </svg>
  </div>
  <div class="layout-option">
    <svg width="20" height="18" viewBox="0 -1 16 16">
      <path d="M1 1v12h14V1H1zm1 3h4.999v8H2V4zm12 8H8.999V4H14v8z"></path>
    </svg>
  </div>
</div>
