
  <div class="filter p-2">

    <input
      #input
      placeholder="Type file name..."
      type="text"
      (input)="filter($event)"
      (keydown)="onKeyDown($event)"
    />
  </div>
  <div class="files">
    <div *ngFor="let file of filteredFiles; let i = index" class="file d-flex px-2" [class.selected]="selectedIndex === i" (click)="select(file)">
      <div class="type d-flex {{file.extension}}">
        <ng-run-icon
          class="icon-file"
          width="15"
          height="15"
          [name]="file.extension || ''"
          *ngIf="file.extension !== 'ts'"
        ></ng-run-icon>
      </div>

      {{ file.name }} <span>{{file.fullPath}}</span>
    </div>
  </div>


