<div [class.d-none]="screenShotMode$ | async" class="overflow-hidden">
  <ng-run-loading *ngIf="!(bundleSuccess$ | async) && !(error$ | async)">Preparing preview...</ng-run-loading>
  <ng-run-loading class="broken" *ngIf="pkgNames$ | async as pkgNames">
    Can't find package{{ pkgNames?.length > 1 ? 's' : '' }}
    <br />
    <br />
    <div class="package-name" *ngFor="let pkg of pkgNames">{{ pkg }}</div>
    <div class="-actions">
      <div class="-confirm" (click)="editorWorker.install(pkgNames)">Install</div>
    </div>
  </ng-run-loading>
  <ng-run-loading *ngIf="installingPkgNames$ | async as installingPkgNames">
    <b class="package-name--info" *ngFor="let pkg of installingPkgNames; let last = last"
      >{{ pkg }} {{ last ? '' : ',&nbsp;' }}</b
    ><br /><br />
    {{ installingPkgNames.length > 1 ? 'packages have' : 'package has' }} been installing...
  </ng-run-loading>
  <div class="preview-toolbar">
    <div
      class="icon-inspect"
      title="Inspect mode"
      [class.icon-inspect--enabled]="inspectMode$ | async"
      (click)="toggleInspectMode()"
    >
      <svg width="16" height="16">
        <use xlink:href="#icon-inspect"></use>
      </svg>
    </div>
    <div class="icon-reload" (click)="reload()">
      <svg width="16" height="16">
        <use xlink:href="#icon-reload"></use>
      </svg>
    </div>
    <input
      type="text"
      class="address-bar"
      aria-label="App address bar"
      [value]="url$ | async"
      (change)="reload($any($event.target).value)"
      (keydown.enter)="reload($any($event.target).value)"
    />
  </div>
  <iframe #child title="Preview" [name]="'preview_' + hash" [src]="previewUrl | safeUrl"></iframe>
</div>

<div *ngIf="screenShotMode$ | async" class="screenshot-preview">
  <div id="screenshot" #screenshot class="screenshot-preview-content"></div>

  <div class="capture-btn" title="Take a screenshot" (click)="saveScreenShot(screenshot.firstElementChild)">
    <div class="capture-inner">
      <div *ngFor="let item of [].constructor(6)" class="capture-blade"></div>
    </div>
  </div>
</div>
