import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

export abstract class Store<State> {
  state$ = new BehaviorSubject<Readonly<State>>(this.initialState as State);

  get state() {
    return this.state$.value;
  }

  protected constructor(private initialState: Partial<State> = null) {}

  select<R>(project: (state: State) => R): Observable<R> {
    return this.state$.asObservable().pipe(
      filter(Boolean),
      map(project),
      distinctUntilChanged()
    );
  }

  setState(partialState: Partial<State>) {
    const nextState = { ...this.state, ...partialState };

    this.state$.next(nextState);
  }
}
