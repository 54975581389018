import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard } from './core/auth.guard';

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        { path: 'about', loadChildren: () => import('./about/about.module').then((m) => m.AboutModule) },
        {
          path: 'lessons',
          loadChildren: () => import('./lessons/lessons.module').then((m) => m.LessonsModule),
          canActivate: [AuthGuard],
        },
      ],
      {}
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
