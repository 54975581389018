import { Injectable } from '@angular/core';
import { Subject, combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';

import { FileItem, FileTypeEnum, ProjectStore } from '@ng-run/playground-store';
import ITextModel = monaco.editor.ITextModel;

const additionalDeps = ['rxjs/operators', 'rxjs/ajax', '@angular/common/http'];

@Injectable({
  providedIn: 'root',
})
export class MonacoEditorStore {
  exports = {};
  extraLibs = {};
  imports = {};
  templatePositions = {};

  private models = new Map<string, ITextModel>();

  private filesAdded$ = new Subject();
  private vendorsLoaded$ = new Subject();

  constructor(private projectStore: ProjectStore) {
    combineLatest([this.filesAdded$, this.vendorsLoaded$])
      .pipe(take(1))
      .subscribe(() => {
        this.enableValidation();
      });
  }

  enableValidation() {
    window.setTimeout(() => {
      monaco.languages.typescript.typescriptDefaults.setDiagnosticsOptions({
        noSemanticValidation: false,
        noSyntaxValidation: false,
      });
    });
  }

  openFile(editor: any, filePath: string) {
    if (!editor) {
      return;
    }

    if (!this.has(filePath)) {
      const newFile = this.projectStore.state.files[filePath];
      this.addModel(filePath, newFile.contents);
    }

    const model = this.getModel(filePath);
    model.setValue(model.getValue());
    model.updateOptions({
      tabSize: 2,
      insertSpaces: true,
    });
    fixEof(model);
    editor.setModel(model);
  }

  addFiles(files: { [key: string]: FileItem }) {
    Object.keys(files).forEach(key => {
      if (files[key].type !== FileTypeEnum.File || this.models.has(key)) {
        return;
      }

      const contents = files[key].contents;
      this.addModel(key, contents);
    });

    this.filesAdded$.next();
  }

  loadVendors(project) {
    let libs = Object.keys(this.isExtraLibsEmpty() ? project.vendors : project.newVendors || project.vendors)
      .filter(file => file.endsWith('.d.ts') || file.endsWith('package.json') || file.endsWith('.metadata.json'))
      .map(file => {
        const relativePath = file.replace('https://unpkg.com/', '');
        const lastIndexOfAt = relativePath.lastIndexOf('@');
        const path =
          'node_modules/' +
          (lastIndexOfAt > 0
            ? relativePath.substr(0, lastIndexOfAt) + relativePath.substr(relativePath.indexOf('/', lastIndexOfAt))
            : relativePath);

        return { path, content: project.vendors[file].contents };
      });

    if (!project.newVendors) {
      libs = libs.concat(
        Object.keys(project.dependencies).map(file => {
          const deps = project.dependencies[file];
          const content = `{"name":"${file}","version":"${deps.version}"${
            deps.hasOwnProperty('types') ? ',"types":"' + deps.types + '"' : ''
          }${deps.hasOwnProperty('typings') ? ',"typings":"' + deps.typings + '"' : ''}}`;

          return { path: `node_modules/${file}/package.json`, content };
        })
      );
    }

    libs.forEach(lib => {
      this.addExtraLib(lib.path, monaco.languages.typescript.typescriptDefaults.addExtraLib(lib.content, lib.path));
    });

    [...Object.keys(project.dependencies), ...additionalDeps].forEach(dep => {
      monaco.languages.typescript.typescriptDefaults.addExtraLib(fakeSource(dep), 'zuz_/' + dep);
    });

    this.vendorsLoaded$.next();
    setTimeout(() => {
      this.loadSuggestions();
    }, 0);
  }

  private loadSuggestions() {
    const deps = [...Object.keys(this.projectStore.state.dependencies), ...additionalDeps];

    monaco.languages.typescript.getTypeScriptWorker().then((worker: any) => {
      worker('').then(proxy => {
        deps.slice().forEach(dep => {
          if (this.exports[dep]) {
            return;
          }

          proxy.getCompletionsAtPosition('zuz_/' + dep, 9).then(res => {
            if (res && res.entries) {
              this.exports[dep] = {
                type: 'global',
                set: res.entries.filter(x => x.name.indexOf('ɵ') !== 0).map(x => x.name),
              };
            }
            deps.shift();
          });
        });
      });
    });
  }

  has(key: string) {
    return this.models.has(key);
  }

  getModel(key: string) {
    return this.models.get(key);
  }

  addModel(key: string, contents: string) {
    if (this.models.has(key)) {
      return;
    }
    const model = monaco.editor.createModel(contents, undefined, monaco.Uri.parse('ng:src/' + key, true));
    fixEof(model);
    this.models.set(key, model);
  }

  updateModel(key: string, contents: string) {
    if (this.models.has(key)) {
      const model = this.models.get(key);
      if (model.getValue() !== contents) {
        model.setValue(contents);
        fixEof(model);
      }
    }
  }

  private _clearModelFor(key: string) {
    const model = this.models.get(key);
    if (model) {
      model.dispose();
      this.models.delete(key);
      delete this.exports[key];
      delete this.imports[key];
      delete this.templatePositions[key];
      this.models.forEach(m => {
        m.setValue(m.getValue());
        fixEof(m);
      });
    }
  }

  clearExportsFor(depName) {
    delete this.exports[depName];
  }

  isExtraLibsEmpty() {
    return Object.keys(this.extraLibs).length === 0;
  }

  addExtraLib(path, disposeFn) {
    this.extraLibs[path] = disposeFn;
  }

  disposeExtraLibsForPackage(packageName) {
    Object.keys(this.extraLibs).forEach(key => {
      if (key.startsWith(`node_modules/${packageName}`)) {
        this.extraLibs[key].dispose();
        delete this.extraLibs[key];
      }
    });
  }

  deleteSolutionItem(file: FileItem) {
    if (file.type === FileTypeEnum.File) {
      const key = file.fullPath;
      this._clearModelFor(key);
    }
  }
}

function fixEof(model) {
  model.setEOL(monaco.editor.EndOfLineSequence.LF);
}

function fakeSource(dep) {
  return `import {  } from "${dep}"`;
}
