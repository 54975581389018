import Sass from 'sass.js/dist/sass.js';
let sassPromise;

function getSass() {
  if (!sassPromise) {
    sassPromise = new Promise(resolve => {
      fetch('https://cdnjs.cloudflare.com/ajax/libs/sass.js/0.10.6/sass.worker.min.js')
        .then(data => data.text())
        .then(content => {
          const bb = new Blob([content], {
            type: 'text/javascript',
          });

          resolve(new Sass(window.URL.createObjectURL(bb)));
        });
    });
  }

  return sassPromise;
}

export function transpileSass(code: string): Promise<any> {
  return getSass().then(sassWorker => {
    return new Promise((resolve, reject) => {
      sassWorker.compile(code, result => {
        if (result.formatted) {
          reject(result.formatted);
          return;
        }

        resolve(result.text);
      });
    });
  });
}
