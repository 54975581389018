<div class="gear-container">
  <div class="gear one">
    <div class="gear-inner">
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
    </div>
  </div>
  <div class="gear two">
    <div class="gear-inner">
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
    </div>
  </div>
</div>
<div class="loading-text">
  <ng-content></ng-content>
</div>
