let loadPromise = null;

export async function loadEditor() {
  return loadPromise || (loadPromise = loadAndPatchEditor());
}

export function setCompilerOptions(options = {}) {
  monaco.languages.typescript.typescriptDefaults.setCompilerOptions({
    target: monaco.languages.typescript.ScriptTarget.ES2015,
    allowNonTsExtensions: true,
    experimentalDecorators: true,
    moduleResolution: monaco.languages.typescript.ModuleResolutionKind.NodeJs,
    module: monaco.languages.typescript.ModuleKind.CommonJS,
    allowJs: true,
    noEmit: true,
    ...options,
    // traceResolution: true
  });
}

async function loadAndPatchEditor(): Promise<any> {
  await load(['vs/editor/editor.main']);
  await import('./editor-languages');

  setCompilerOptions();

  return patchEditorWorkerClient();
}

export function load(paths: string[]): Promise<any> {
  return new Promise(resolve => {
    __non_webpack_require__(paths, (...modules) => {
      resolve(...modules);
    });
  });
}

// https://github.com/microsoft/monaco-editor/issues/461
// don't stop models synchronization
async function patchEditorWorkerClient() {
  const editorWorkerServiceImpl = await load(['vs/editor/common/services/editorWorkerServiceImpl']);

  const getOrCreateModelManager = editorWorkerServiceImpl.EditorWorkerClient.prototype._getOrCreateModelManager;
  editorWorkerServiceImpl.EditorWorkerClient.prototype._getOrCreateModelManager = function(proxy) {
    const modelManager = getOrCreateModelManager.apply(this, arguments);
    modelManager._checkStopModelSync = noop;

    return modelManager;
  };
}

function noop() {}
