import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '@ng-run/shared';
import { EditorComponent } from './editor/editor.component';
import { PreviewComponent } from './preview/preview.component';
import { EditorHeaderComponent } from './editor/editor-header/editor-header.component';
import { PlaygroundPageComponent } from './playground.page';
import { HeaderComponent } from './header/header.component';
import { LayoutPickerComponent } from './header/layout-picker/layout-picker.component';
import { ThemePickerComponent } from './header/theme-picker/theme-picker.component';
import { UserPanelComponent } from './header/user-panel/user-panel.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SolutionExplorerModule } from './sidebar/solution-explorer/solution-explorer.module';
import { CliGeneratorComponent } from './sidebar/cli-generator/cli-generator.component';
import { DependencyResolverComponent } from './sidebar/dependency-resolver/dependency-resolver.component';
import { ExternalResourcesComponent } from './sidebar/external-resources/external-resources.component';
import { LatestProjectsComponent } from './sidebar/latest-projects/latest-projects.component';
import { ScrollableDirective } from './sidebar/latest-projects/scrollable.directive';
import { GoToFileDialogComponent } from './editor/go-to-file-dialog/go-to-file-dialog.component';
import { SearchDialogComponent } from './editor/search-dialog/search-dialog.component';

@NgModule({
  declarations: [
    PlaygroundPageComponent,
    EditorComponent,
    GoToFileDialogComponent,
    SearchDialogComponent,
    PreviewComponent,
    EditorHeaderComponent,
    HeaderComponent,
    ThemePickerComponent,
    UserPanelComponent,
    SidebarComponent,
    LayoutPickerComponent,
    CliGeneratorComponent,
    DependencyResolverComponent,
    ExternalResourcesComponent,
    LatestProjectsComponent,
    ScrollableDirective,
  ],
  imports: [
    SharedModule,
    SolutionExplorerModule,
    RouterModule.forChild([
      {
        path: 'fork/:workspace',
        component: PlaygroundPageComponent,
      },
      {
        path: 'lesson/:lessonId',
        component: PlaygroundPageComponent,
      },
      {
        path: '**',
        component: PlaygroundPageComponent,
      },
    ]),
  ],
})
export class PlaygroundModule {}
