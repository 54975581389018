<div class="latest-projects">
  <div *ngIf="loading$ | async" class="flex-center p-2">
    <div class="loader"></div>
  </div>

  <ng-container *ngIf="projects$ | async as projects">
    <ng-container *ngIf="projects.length">
      <ul class="list">
        <li class="project" *ngFor="let item of projects">
          <a class="link" href="/edit/{{ item.id }}" [class.active]="item.current">
            <span class="d-block">{{ item.title }}</span>
            <span class="time">{{ item.timeAgo }}</span>
          </a>
        </li>
      </ul>
      <div *ngIf="projects.length === 5" class="flex-center mt-1 trigger see-all" (click)="openAllProjects()">
        see all projects
      </div>
    </ng-container>
  </ng-container>
</div>
<div *ngIf="opened$ | async" class="projects-modal">
  <div class="projects-modal__content" scrollable tabindex="0" (scrollPosition)="scrollHandler($event)">
    <ul class="projects-modal__list">
      <li class="-project" *ngFor="let item of page.data | async">
        <a class="-link" href="/edit/{{ item.id }}" [class.active]="item.current">
          <span class="-title">{{ item.title }}</span>
          <span class="-time">{{ item.timeAgo }}</span>
        </a>
      </li>
    </ul>
    <div *ngIf="page.loading | async">
      Loading...
    </div>
  </div>
</div>
<div class="fake-bg" *ngIf="opened$ | async" (click)="close()"></div>
