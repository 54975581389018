import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';

import { ProjectStore } from '@ng-run/playground-store';

@Component({
  selector: 'ng-run-external-resources',
  templateUrl: './external-resources.component.html',
  styleUrls: ['./external-resources.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExternalResourcesComponent {
  resources$ = this.projectStore.externalResources$;

  supportedExtensions = ['css', 'js'];

  @ViewChild('newItemControl', { static: true }) newItemControl: ElementRef;

  errorElem: HTMLElement;

  constructor(private projectStore: ProjectStore) {}

  addSource() {
    const value = this.newItemControl.nativeElement.value;
    const errorMsg = this.validate(value);

    if (errorMsg) {
      this.openError(errorMsg, this.newItemControl.nativeElement);
      return;
    }

    this.projectStore.addExternalResource(value);

    this.closeError();
    this.newItemControl.nativeElement.value = '';
  }

  deleteSource(resource: string) {
    this.projectStore.removeExternalResource(resource);
  }

  private openError(text: string, target: HTMLInputElement) {
    const elem = document.createElement('div');
    elem.className = 'solution-tree-item__error';
    elem.textContent = text;

    const { top, left } = target.getBoundingClientRect();
    elem.style.top = top + 'px';
    elem.style.left = left + target.clientWidth + 'px';
    document.body.appendChild(elem);
    this.errorElem = elem;
    setTimeout(() => {
      this.closeError();
    }, 2_000);
  }

  private closeError() {
    if (this.errorElem) {
      document.body.removeChild(this.errorElem);
      this.errorElem = null;
    }
  }

  private validate(value: string) {
    if (!value || !value.trim()) {
      return `Please enter url.`;
    }

    const msg = 'Please consider another url.';

    if (!/[a-z0-9\-]+$/i.test(value)) {
      return `Sorry, only alphanumeric values and '-' are allowed! ` + msg;
    }

    if (!this.supportedExtensions.includes(value.split('.').pop())) {
      return `Sorry, we only support ${this.supportedExtensions.join(', ')} extensions. ` + msg;
    }
  }
}
