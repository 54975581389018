<input
  type="text"
  id="external-resources"
  class="input"
  placeholder=".js or .css url"
  (keyup.enter)="addSource()"
  #newItemControl
  autocomplete="off"
  autocorrect="off"
  autocapitalize="off"
  spellcheck="false"
/>
<ul class="list">
  <li class="flex-y-center mb-2" *ngFor="let resource of resources$ | async">
    <a [href]="resource" class="-item-link" target="_blank" [title]="resource">{{ resource.split('/').pop() }}</a>
    <svg class="icon-delete ml-1" (click)="deleteSource(resource)"><use xlink:href="#icon-delete"></use></svg>
  </li>
</ul>
