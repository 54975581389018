import { Component, Input } from '@angular/core';

@Component({
  selector: 'ng-run-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent {
  @Input() name: string;

  @Input() width = '24';

  @Input() height = '24';
}
