export interface ProjectState {
  id?: string;
  title?: string;
  authorId?: string;
  author?: {
    displayName?: string;
    photoURL?: string;
  };
  selectedFile: string;
  manifest: { indexHtml: string; entry: string; styles: string };
  files: { [key: string]: FileItem };
  dependencies: any;
  externalResources?: string[];
  dirty?: boolean;
  aot?: boolean;
  vimMode?: boolean;
  compilerOptions?: any;
  target?: string;
  nonNg?: boolean;
  recordingFileName?: string;
  record?: boolean;
}

export interface FileItem {
  name: string;
  type: FileTypeEnum;
  contents: string;
  fullPath?: string;
  dirty?: boolean;
}

export enum FileTypeEnum {
  Folder = 'folder',
  File = 'file',
}
