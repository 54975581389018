import { AfterViewInit, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { skip, takeUntil, tap } from 'rxjs/operators';

import { LayoutType, PlaygroundStore, ProjectStore, toggleAot } from '@ng-run/playground-store';
import { EditorWorker } from './editor/editor.worker';
import { MonacoEditorStore } from './editor/monaco.store';
import { SplitterComponent } from '../shared/components/splitter/splitter.component';
import { HeaderComponent } from './header/header.component';
import { ActivatedRoute } from '@angular/router';

const sidebarWidth = (240 * 100) / window.innerWidth;
const otherAreasWidth = (100 - sidebarWidth) / 3;

@Component({
  selector: 'ng-run-playground-page',
  templateUrl: './playground.page.html',
  styleUrls: ['./playground.page.scss'],
})
export class PlaygroundPageComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(SplitterComponent, { static: true }) splitter: SplitterComponent;

  @ViewChild('header', { static: true }) header: HeaderComponent;

  areas = [sidebarWidth, otherAreasWidth, otherAreasWidth, otherAreasWidth];

  goToFileDialogOpened$ = new BehaviorSubject(false);
  searchDialogOpened$ = new BehaviorSubject(false);

  private destroyed$ = new Subject();

  private sizesCache;

  constructor(
    private playgroundStore: PlaygroundStore,
    private projectStore: ProjectStore,
    private editorWorker: EditorWorker,
    private monacoStore: MonacoEditorStore,
    private route: ActivatedRoute
  ) {}

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (
      (event.ctrlKey || event.metaKey) &&
      event.key.toLowerCase() === 's' &&
      this.playgroundStore.state.reloadMode === 'save'
    ) {
      this.editorWorker.doSave();
      if (this.projectStore.state.id) {
        this.projectStore.save(false).then();
      }

      event.preventDefault();
    }

    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 'p' && !event.altKey) {
      event.preventDefault();
      this.goToFileDialogOpened$.next(true);
    }

    if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 'f' && event.shiftKey) {
      // event.preventDefault();
      // this.searchDialogOpened$.next(true);
    }
  }

  ngOnInit() {
    const workspace = this.route.snapshot.params ? this.route.snapshot.params.workspace : null;
    const lessonId = this.route.snapshot.params ? this.route.snapshot.params.lessonId : null;
    const { testMode } = this.playgroundStore.state;
    this.projectStore.loadProject(testMode, workspace, lessonId).then(() => this.trackAotChanges());
  }

  trackAotChanges() {
    this.projectStore.aotEnabled$.pipe(skip(1), takeUntil(this.destroyed$)).subscribe((aotEnabled) => {
      const { key, contents } = toggleAot(aotEnabled, this.projectStore.state);
      this.monacoStore.updateModel(key, contents);
      this.editorWorker.detectChanges(key, contents);
    });
  }

  ngAfterViewInit() {
    this.updateLayout();
    combineLatest([
      this.projectStore.selectedFile$.pipe(tap(() => this.header.toggleMenu(false))),
      this.playgroundStore.layout$,
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.updateLayout());
  }

  get isMobile() {
    return window.matchMedia('(max-width: 960px)').matches;
  }

  updateLayout() {
    const { areas } = this.splitter;
    const isSingleLayout = this.playgroundStore.state.layout === LayoutType.One;
    const isMobile = this.isMobile;
    if (isMobile) {
      this.resize();
    } else {
      const pixels = this.splitter.areas$.value.map(
        (percent) => percent * (this.splitter.containerSize - this.splitter.separatorsSize)
      );

      if (isSingleLayout && !areas[1].hidden && !areas[2].hidden) {
        areas[2].hidden = true;
        const removedSize = pixels.splice(2, 1);
        pixels[1] += removedSize[0] + this.splitter.separatorSize;
        const areaSize = this.splitter.containerSize - this.splitter.separatorsSize;
        const newAreas = pixels.map((px) => (px / areaSize) * 100);
        areas[0].size = newAreas[0];
        areas[1].size = newAreas[1];
        areas[3].size = newAreas[2];
      } else if (!isSingleLayout && (areas[1].hidden || areas[2].hidden)) {
        if (areas[2].hidden) {
          areas[2].hidden = false;
        } else {
          areas[1].hidden = false;
        }
        const halfOfPrev = pixels[1] / 2 - this.splitter.separatorSize / 2;
        pixels[1] = halfOfPrev;
        pixels.splice(2, 0, halfOfPrev);
        const areaSize = this.splitter.containerSize - this.splitter.separatorsSize;
        const newAreas = pixels.map((px) => (px / areaSize) * 100);
        newAreas.forEach((size, i) => (areas[i].size = size));
      }

      this.splitter.updateLayout();
    }

    if (isSingleLayout || isMobile) {
      const isHtml = this.projectStore.state ? this.projectStore.state.selectedFile.endsWith('html') : false;
      if (isHtml && areas[2].hidden) {
        areas[2].size = areas[1].size;
        areas[1].hidden = true;
        areas[2].hidden = false;
      } else if (!isHtml && areas[1].hidden) {
        areas[1].size = areas[2].size;
        areas[2].hidden = true;
        areas[1].hidden = false;
      }

      this.splitter.updateLayout();
    }
  }

  @HostListener('window:resize')
  resize() {
    const { areas } = this.splitter;
    const isMobile = this.isMobile;
    if (isMobile && !areas[0].hidden) {
      this.sizesCache = areas.map((a) => a.size);
      areas[0].hidden = true;
      const isHtml = this.projectStore.state ? this.projectStore.state.selectedFile.endsWith('html') : false;
      areas[isHtml ? 1 : 2].hidden = true;
      areas[0].size = 0;
      areas[1].size = isHtml ? 0 : 100;
      areas[2].size = isHtml ? 100 : 0;
      areas[3].size = 0;
    } else if (!isMobile && areas[0].hidden) {
      this.header.toggleMenu(false);
      areas[0].hidden = false;
      if (this.playgroundStore.state && this.playgroundStore.state.layout === LayoutType.Two) {
        areas[1].hidden = areas[2].hidden = false;
        this.areas.forEach((size, i) => (areas[i].size = size));
      } else if (this.sizesCache) {
        this.sizesCache.forEach((size, i) => (areas[i].size = size));
        if (areas[2].hidden) {
          areas[1].size += areas[2].size;
        } else if (areas[1].hidden) {
          areas[2].size += areas[1].size;
        }
      }
    }

    this.splitter.updateLayout();
  }

  toggleGoToFileDialog(open: boolean) {
    this.goToFileDialogOpened$.next(open);
  }

  toggleSearchDialog(open: boolean) {
    this.searchDialogOpened$.next(open);
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }
}
