import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ɵdetectChanges as detectChanges,
} from '@angular/core';

import { ProjectService } from '@ng-run/services';
import { ProjectStore } from '@ng-run/playground-store';
import { MonacoEditorStore } from '../../editor/monaco.store';
import { EditorWorker } from '../../editor/editor.worker';

@Component({
  selector: 'ng-run-dependency-resolver',
  templateUrl: './dependency-resolver.component.html',
  styleUrls: ['./dependency-resolver.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DependencyResolverComponent implements OnInit {
  @Input() depName: string;

  @Input() version: string;

  @Output() versionChanged = new EventEmitter();

  versions: string[];

  constructor(
    private projectService: ProjectService,
    private projectStore: ProjectStore,
    private monacoStore: MonacoEditorStore,
    private editorWorker: EditorWorker
  ) {}

  ngOnInit() {
    document.querySelector('ng-run-sidebar').addEventListener(
      'mouseenter',
      () => {
        this.projectService.getVersions(this.depName).then(res => {
          this.versions = res;
          detectChanges(this);
        });
      },
      { once: true }
    );
  }

  changeVersion(version: string) {
    const payload = {
      name: this.depName,
      version: version,
      prevVersion: this.version,
    };

    if (this.depName.startsWith('@angular') && parseInt(version, 10) > 7) {
      const packagesToUpdate = [];
      const deps = this.projectStore.state.dependencies;

      Object.keys(deps).forEach(depName => {
        if (!depName.startsWith('@angular')) {
          return;
        }

        const depPayload = {
          name: depName,
          version: version,
          prevVersion: deps[depName].version,
        };

        packagesToUpdate.push(depPayload);

        this.monacoStore.clearExportsFor(depName);
        this.monacoStore.disposeExtraLibsForPackage(depName);
        this.projectStore.updateDep(depPayload);
      });

      if (!packagesToUpdate.length) {
        return;
      }

      this.editorWorker.installState$.next(packagesToUpdate.map(pkg => `${pkg.name}@${pkg.version}`));
      this.editorWorker.post({
        type: 'UPDATE_DEPS',
        payload: {
          aot: this.projectStore.state.aot ? this.projectStore.getAotVersion() : false,
          deps: packagesToUpdate,
        },
      });

      return;
    }

    this.editorWorker.installState$.next([`${this.depName}@${version}`]);
    this.monacoStore.clearExportsFor(this.depName);
    this.monacoStore.disposeExtraLibsForPackage(this.depName);
    this.projectStore.updateDep(payload);
    this.editorWorker.post({
      type: 'UPDATE_DEPS',
      payload: {
        aot: this.projectStore.state.aot ? this.projectStore.getAotVersion() : false,
        deps: [payload],
      },
    });
  }
}
