import { PluginConsumer } from '../../plugin-consumer';

export function plugIn(editor: monaco.editor.IStandaloneCodeEditor, self: PluginConsumer) {
  editor.addCommand(
    // tslint:disable-next-line:no-bitwise
    monaco.KeyMod.CtrlCmd | monaco.KeyMod.Alt | monaco.KeyMod.Shift | monaco.KeyCode.KEY_P,
    () => format(editor, self)
  );
}

function format(editor: monaco.editor.IStandaloneCodeEditor, self: PluginConsumer) {
  const PRETTIER_VERSION = '1.18.2';
  const prettierBasePath = `https://unpkg.com/prettier@${PRETTIER_VERSION}/`;

  (window['require'] as any)(
    [
      `${prettierBasePath}standalone.js`,
      `${prettierBasePath}parser-typescript.js`,
      `${prettierBasePath}parser-postcss.js`,
      `${prettierBasePath}parser-html.js`,
    ],
    (prettier, { parsers: tsParsers }, { parsers: cssParsers }, { parsers: htmlParsers }) => {
      const model = editor.getModel();
      const cursorOffset = model.getOffsetAt(editor.getPosition());
      const parsers = { ...tsParsers, ...cssParsers, ...htmlParsers };

      const lang = model.getModeId();
      const formatResult = prettier.formatWithCursor(model.getValue(), {
        parser: lang,
        plugins: [{ parsers }],
        cursorOffset,
        ...self.playgroundStore.state.prettierConfig,
      });

      model.setValue(formatResult.formatted);
      const newPosition = model.getPositionAt(formatResult.cursorOffset);
      editor.setPosition(newPosition);
      self.refreshDecorators();
    }
  );
}
