import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  ɵdetectChanges as detectChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { FileTypeEnum, ProjectStore } from '@ng-run/playground-store';

@Component({
  selector: 'ng-run-go-to-file',
  templateUrl: './go-to-file-dialog.component.html',
  styleUrls: ['./go-to-file-dialog.component.scss'],
})
export class GoToFileDialogComponent implements OnInit {
  @Output() closed = new EventEmitter();

  @ViewChild('input')
  set input(inputEl: ElementRef<HTMLInputElement>) {
    if (inputEl) {
      inputEl.nativeElement.focus();
    }
  }

  files = [];
  filteredFiles: { name: string; fullPath: string; extension: string }[] = [];
  selectedIndex = 0;

  constructor(private projectStore: ProjectStore) {}

  ngOnInit() {
    const { files, selectedFile } = this.projectStore.state;
    this.files = Object.keys(files).filter(key => files[key].type === FileTypeEnum.File && key !== selectedFile);
    const selectedFiles = this.projectStore.selectedFiles.slice(1);

    this.files = this.files.sort((a, b) => {
      const indexA = selectedFiles.indexOf(a);
      const indexB = selectedFiles.indexOf(b);
      if (indexA > -1 && indexB === -1) {
        return -1;
      }
      if (indexB > -1 && indexA === -1) {
        return 1;
      }

      if (indexA > -1 && indexB > -1) {
        return indexA - indexB;
      }

      return 0;
    });
    this.files = this.filteredFiles = this.files.map(key => ({
      name: files[key].name,
      fullPath: key,
      extension: key.substr(key.lastIndexOf('.') + 1),
    }));
  }

  onKeyDown(event: any) {
    switch (event.key) {
      case 'ArrowUp': {
        this.selectedIndex--;
        if (this.selectedIndex < 0) {
          this.selectedIndex = this.filteredFiles.length - 1;
        }
        detectChanges(this);
        break;
      }
      case 'ArrowDown': {
        this.selectedIndex++;
        if (this.selectedIndex === this.filteredFiles.length) {
          this.selectedIndex = 0;
        }
        detectChanges(this);
        break;
      }
      case 'Escape': {
        this.closed.emit();
        break;
      }
      case 'Enter': {
        this.select(this.filteredFiles[this.selectedIndex]);
        break;
      }
    }
  }

  filter(event: Event) {
    this.filteredFiles = this.files.filter(file => file.fullPath.includes((event.target as any).value));
    this.selectedIndex = 0;
    detectChanges(this);
  }

  select(file: { name: string; fullPath: string; extension: string }) {
    this.projectStore.selectFile(file.fullPath);
    this.closed.emit();
  }
}
