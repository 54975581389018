import { InlineHtmlCompletion } from '@ng-run/monaco/html/html-completion';
import { PluginConsumer } from '../../plugin-consumer';

export function plugIn(editor: monaco.editor.IStandaloneCodeEditor, self: PluginConsumer) {
  const completionProvider = new InlineHtmlCompletion();
  monaco.languages.registerCompletionItemProvider('typescript', completionProvider);

  monaco.editor.registerCommand('resolveImport', (accessor, args: ImportOptions) => {
    args.toFile = self.projectStore.state.files[self.openedFile];
    addImport(editor, self, args);
  });

  const ngSnippets = [
    {
      label: 'ngc',
      kind: monaco.languages.CompletionItemKind.Snippet,
      insertText: [
        '@Component({',
        `\tselector: '$1',`,
        '\ttemplate: `$3`',
        '})',
        'export class $2Component {',
        '\t$4',
        '}',
      ].join('\n'),
      insertTextRules: 4,
      documentation: 'Angular component',
    } as any,
    {
      label: 'ngd',
      kind: monaco.languages.CompletionItemKind.Snippet,
      insertText: ['@Directive({', `\tselector: '$1',`, '})', 'export class $2Directive {', '\t$3', '}'].join('\n'),
      insertTextRules: 4,
      documentation: 'Angular directive',
    },
  ];

  monaco.languages.registerCompletionItemProvider('typescript', {
    provideCompletionItems: (model, position) => {
      if (completionProvider.matched) {
        return { suggestions: [] };
      }

      const resource = model.uri;
      const offset = model.getOffsetAt(position);

      const isInStringCheck = self.editorWorker.worker.postMessage({
        type: 'IS_IN_STRING',
        payload: { contents: model.getValue(), offset },
      });
      const suggestionResultCheck = monaco.languages.typescript
        .getTypeScriptWorker()
        .then((worker: any) => worker('').then(proxy => proxy.getCompletionsAtPosition(resource.toString(), offset)));

      return Promise.all([isInStringCheck, suggestionResultCheck]).then(([isInStringRes, info]) => {
        if (isInStringRes.isInString || (info && info.isMemberCompletion)) {
          return Promise.resolve({ suggestions: [] });
        }

        const word = model.getWordUntilPosition(position).word.toLowerCase();

        const exports: ImportOptions[] = Object.keys(self.monacoStore.exports).reduce((acc, key) => {
          if (key !== self.openedFile) {
            acc.push(
              ...self.monacoStore.exports[key].set.map((name: any) => {
                return { name: name, type: self.monacoStore.exports[key].type, fromFile: key };
              })
            );
          }
          return acc;
        }, []);

        const suggestions = exports
          .filter(exp => {
            return (
              exp.name.toLowerCase().indexOf(word) > -1 &&
              self.monacoStore.imports[self.openedFile].indexOf(exp.name) === -1
            );
          })
          .map(exportOptions => {
            return {
              label: exportOptions.name,
              kind: monaco.languages.CompletionItemKind.Reference,
              detail: `[Auto-Import] ${exportOptions.name} [${exportOptions.fromFile}]`,
              insertText: exportOptions.name,
              documentation: `[AI]  Import ${exportOptions.name} from ${exportOptions.fromFile}`,
              command: { title: 'AI: Autocomplete', id: 'resolveImport', arguments: [exportOptions] },
            };
          })
          .concat(ngSnippets);

        return {
          suggestions,
        };
      });
    },
  });

  enableAutoImportFromKeyboard(editor, self);
}

function enableAutoImportFromKeyboard(editor: monaco.editor.IStandaloneCodeEditor, self: PluginConsumer) {
  const getImportClause = () => {
    const model = editor.getModel();

    const position = editor.getPosition();
    const wordAtPosition = model.getWordAtPosition(position);
    if (!wordAtPosition) {
      return {
        clause: null,
      };
    }
    const word = wordAtPosition.word.toLowerCase();
    const exports: ImportOptions[] = Object.keys(self.monacoStore.exports).reduce((acc, key) => {
      if (key !== self.openedFile) {
        acc.push(
          ...self.monacoStore.exports[key].set.map(x => {
            return { name: x, type: self.monacoStore.exports[key].type, fromFile: key };
          })
        );
      }
      return acc;
    }, []);

    return {
      range: new monaco.Range(
        position.lineNumber,
        wordAtPosition.startColumn,
        position.lineNumber,
        wordAtPosition.endColumn
      ),
      clause: exports.find(exp => {
        return exp.name.toLowerCase() === word && self.monacoStore.imports[self.openedFile].indexOf(exp.name) === -1;
      }),
    };
  };

  let autoImportHighlightDecorators = null;
  editor.onKeyDown(e => {
    if (e.altKey) {
      e.preventDefault();
    }
    if (!e.altKey || autoImportHighlightDecorators) {
      return;
    }

    const importClause = getImportClause();
    if (importClause.clause) {
      autoImportHighlightDecorators = editor.deltaDecorations(
        [],
        [{ range: importClause.range, options: { inlineClassName: 'myInlineDecoration' } }]
      );
    }
  });
  editor.onKeyUp(e => {
    if (!e.altKey || !autoImportHighlightDecorators) {
      return;
    }

    // Clear highlight
    editor.deltaDecorations(autoImportHighlightDecorators, []);
    autoImportHighlightDecorators = null;
  });

  // auto import
  editor.addCommand(monaco.KeyMod.Alt | monaco.KeyCode.Enter, () => {
    const importClause = getImportClause().clause;
    if (importClause) {
      importClause.toFile = self.projectStore.state.files[self.openedFile];
      addImport(editor, self, importClause);
    }
  });
}

function addImport(editor: monaco.editor.IStandaloneCodeEditor, self: PluginConsumer, options: ImportOptions) {
  self.editorWorker.worker
    .postMessage({
      type: 'ADD_IMPORT',
      payload: options,
    })
    .then(data => completeAutoImport(editor, data));
}

function completeAutoImport(editor: monaco.editor.IStandaloneCodeEditor, data) {
  const model = editor.getModel();

  const insertingPosition = model.getPositionAt(data.position);
  const range = new monaco.Range(
    insertingPosition.lineNumber,
    insertingPosition.column,
    insertingPosition.lineNumber,
    insertingPosition.column
  );

  const op = { identifier: { major: 1, minor: 1 }, range: range, text: data.toInsert, forceMoveMarkers: true };
  editor.executeEdits('', [op]);
}
