import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { AuthService } from '@ng-run/auth';
import { Store } from '@ng-run/store';
import { DBProject, ProjectService } from '@ng-run/services';
import { getParameterByName, updateQueryStringParameter } from '@ng-run/utils';
import { FileItem, ProjectState } from './project.state';
import { defaultProject } from './project.defaults';
import { WORKSPACES } from './workspaces';
import { DBLesson, LessonsService } from '../../../core/services/lessons.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectStore extends Store<ProjectState> {
  isProjectDirty$ = this.select((state) => state.dirty);

  projectLoaded$ = this.select((state) => state).pipe(take(1));

  target$ = this.select((state) => state.target);

  author$ = this.select((state) => state.author);

  nonNg$ = this.select((state) => !!state.nonNg);

  isLesson$ = this.select((state) => !!state.recordingFileName);

  isLessonRecording$ = this.select((state) => state.recordingFileName === '');

  dependencies$ = this.select((state) => state.dependencies).pipe(
    map((dependencies) =>
      Object.keys(dependencies)
        .map((depName) => ({ ...dependencies[depName], name: depName }))
        .filter((dep) => !dep.fake)
        .sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        })
    )
  );

  externalResources$ = this.select((state) => state.externalResources);

  canEdit$ = combineLatest([this.authService.user$, this.select((state) => state.authorId)]).pipe(
    map(([user, authorId]) => authorId && user && authorId === user.uid)
  );

  isTestsSupported$ = this.select((state) => state && state.files && !!state.files['src/test.ts']);

  ngVersion$ = this.select((state) => state.dependencies).pipe(
    map((deps) => (deps['@angular/compiler'] ? parseInt(deps['@angular/compiler'].version, 10) : 9))
  );

  aotEnabled$ = this.select((state) => state.aot !== undefined && !!state.aot);

  vimEnabled$ = this.select((state) => !!state.vimMode);

  recording$ = this.select((state) => !!state.record);

  selectedFile$ = this.select((state) => state.selectedFile);

  selectedFiles = [];

  wasRecorded = false;

  get isSaveEnabled() {
    return this.wasRecorded || (!!this.state.recordingFileName && this.state.authorId === this.authService.user.uid);
  }

  getAotVersion() {
    const compilerDeps = this.state.dependencies['@angular/compiler'];
    return compilerDeps.version;
  }

  constructor(
    private authService: AuthService,
    private projectService: ProjectService,
    private location: Location,
    private lessonService: LessonsService
  ) {
    super();
  }

  save(forked: boolean): Promise<string | boolean> {
    const { uid, displayName, photoURL } = this.authService.user;
    if (!uid) {
      return Promise.resolve(false);
    }

    const {
      id,
      title,
      files,
      dependencies,
      selectedFile,
      manifest,
      externalResources,
      aot,
      compilerOptions,
      target,
      nonNg,
      recordingFileName,
    } = this.state;
    const project: DBProject | DBLesson = {
      ...(!forked && id && { id }),
      title,
      authorId: uid,
      author: {
        displayName,
        photoURL,
      },
      files: JSON.stringify(files, (key, value) => (['dirty', 'fullPath'].includes(key) ? undefined : value)),
      dependencies,
      selectedFile: selectedFile || '',
      manifest: manifest || '',
      externalResources: JSON.stringify(externalResources),
      aot,
      nonNg: nonNg || false,
      compilerOptions: compilerOptions || null,
      target: target || 'es5',
      ...(recordingFileName ? { recordingFileName } : {}),
    };

    return (recordingFileName ? this.lessonService : this.projectService).save(project).then((res) => {
      this.setState({
        ...res,
        dirty: false,
      });
      return res.id;
    });
  }

  loadProject(testMode: string, workspace, lessonId) {
    let projectResult = Promise.resolve((workspace && WORKSPACES[workspace]) || defaultProject);
    const path = document.location.pathname;

    if (lessonId) {
      projectResult = this.lessonService.loadFromFirestore(lessonId).then((project) => {
        if (!project) {
          this.location.go('/');
          location.reload();
          return null;
        }

        project.id = lessonId;
        return project;
      });
    } else if (path.startsWith('/edit/')) {
      const id = path.split('/edit/').join('');
      if (!id) {
        this.location.go('/');
      } else {
        projectResult = this.projectService.loadFromFirestore(id).then((project) => {
          if (project.manifest) {
            const files = JSON.parse(project.files);
            const entries = {
              true: 'src/test.ts',
              false: 'src/main.ts',
            };

            if (files[entries[testMode]]) {
              project.manifest.entry = entries[testMode];
            }
          }

          project.id = id;

          return project;
        });
      }
    } else if (path.startsWith('/github/')) {
      projectResult = this.projectService
        .loadFromGithub(document.location.pathname.replace('/github/', ''))
        .then((project) => {
          if (testMode === 'true') {
            project.manifest.entry = project.selectedFile = 'src/test.ts';
          }

          project.selectedFile = project.manifest.entry || 'src/main.ts';

          return project;
        });
    } else if (path.startsWith('/s')) {
      projectResult = this.projectService.loadFromStore(document.location.search).then((project) => {
        return { ...defaultProject, ...project, selectedFile: project.manifest.entry };
      });
    }

    return projectResult.then((project) => {
      if (!project) {
        this.location.go('/');
        project = defaultProject;
      }

      if (typeof project.files === 'string') {
        project.files = JSON.parse(project.files);
      }
      project.externalResources = JSON.parse((project.externalResources as any) || '[]');
      project.selectedFile = project.selectedFile || defaultProject.selectedFile;

      const open = getParameterByName('open');
      if (open) {
        const file = decodeURIComponent(open);
        if (project.files[file]) {
          project.selectedFile = file;
        }
      }

      project.dependencies = project.dependencies || defaultProject.dependencies;
      project.manifest = project.manifest || defaultProject.manifest;

      const aot = !!project.aot && parseInt(project.dependencies['@angular/compiler'].version, 10) >= 9;
      if (!project.nonNg) {
        const { key, contents } = toggleAot(aot, project);
        project.files[key] = {
          ...project.files[key],
          contents,
        };
      }

      this.selectedFiles = [project.selectedFile];

      this.setState({
        ...project,
        dirty: false,
        aot,
        target: project.target || 'es5',
        vimMode: localStorage.getItem('vim') === 'true',
        record: localStorage.getItem('record') === 'true',
      });

      return project;
    });
  }

  selectFile(fullPath: string) {
    if (this.state.selectedFile === fullPath) {
      return;
    }

    this.setState({
      selectedFile: fullPath,
    });
    this.selectedFiles = this.selectedFiles.filter((file) => file !== fullPath);
    this.selectedFiles.unshift(fullPath);
    this.location.replaceState(updateQueryStringParameter('open', encodeURIComponent(fullPath)));
  }

  addItem(payload: { value: { contents: string; name: string; type: any }; key: string }) {
    this.setState({
      dirty: true,
      files: { ...this.state.files, [payload.key]: payload.value },
    });
  }

  deleteItem(item: FileItem) {
    const files = this.state.files;
    this.setState({
      dirty: true,
      files: Object.keys(files).reduce((acc, cur) => {
        if (item.name ? cur.startsWith(item.fullPath) : cur === item.fullPath) {
          return acc;
        }
        acc[cur] = files[cur];
        return acc;
      }, {} as any),
    });
  }

  merge(payload: { add: {}; remove: any[] }) {
    const files = this.state.files;
    this.setState({
      dirty: true,
      files: Object.assign(
        Object.keys(files).reduce((acc, cur) => {
          if (payload.remove.indexOf(cur) > -1) {
            return acc;
          }
          acc[cur] = files[cur];
          return acc;
        }, {} as any),
        payload.add
      ),
    });
  }

  setDirtyOnFiles(dirty: boolean, filePaths?: string[]) {
    let { files } = this.state;
    files = Object.keys(files).reduce((acc, cur) => {
      if ((filePaths ? filePaths.includes(cur) : true) && files[cur].dirty !== dirty) {
        files[cur] = { ...files[cur], dirty };
      }
      acc[cur] = files[cur];
      return acc;
    }, {});

    this.setState({
      dirty: true,
      files,
    });
  }

  updateDeps(dependencies: any) {
    this.setState({
      dependencies,
      dirty: true,
    });
  }

  updateDep(dep: { name: string; version: string }) {
    const deps = this.state.dependencies;
    this.setState({
      dependencies: {
        ...deps,
        [dep.name]: {
          ...deps[dep.name],
          version: dep.version,
        },
      },
      dirty: true,
    });
  }

  deleteProject(id: any) {
    return this.projectService.deleteProject(id);
  }

  setDirty() {
    if (!this.state.dirty) {
      this.setState({
        dirty: true,
      });
    }
  }

  setTitle(title: string) {
    this.setState({
      title,
      dirty: true,
    });
  }

  addExternalResource(value: any) {
    const resources = this.state.externalResources;
    if (resources.includes(value)) {
      return;
    }

    this.setState({
      externalResources: [...resources, value],
      dirty: true,
    });
    preview.postMessage(
      {
        type: 'ADD_RESOURCE',
        payload: value,
      },
      '*'
    );
  }

  removeExternalResource(resource: string) {
    const resources = this.state.externalResources;
    this.setState({
      externalResources: resources.filter((res) => res !== resource),
      dirty: true,
    });
    preview.postMessage(
      {
        type: 'REMOVE_RESOURCE',
        payload: resource,
      },
      '*'
    );
  }

  toggleAotStatus() {
    this.setState({
      aot: !this.state.aot,
    });
  }

  toggleVimMode() {
    const mode = !this.state.vimMode;
    localStorage.setItem('vim', mode.toString());
    location.reload();
  }

  toggleRecording() {
    const mode = !this.state.record;
    localStorage.setItem('record', mode.toString());
    location.reload();
  }

  updateCompilerOptions(compilerOptions) {
    this.setState({
      dirty: true,
      compilerOptions,
    });
  }

  setTarget(target: any) {
    this.setState({
      target,
    });
  }
}

export function toggleAot(aot, project) {
  const dynamicPlatform = `import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';`;
  const platform = `import { platformBrowser } from '@angular/platform-browser';`;

  const mainFilePath = Object.keys(project.files).find((key) => key.endsWith('main.ts'));
  if (!mainFilePath) {
    return;
  }
  const mainFile = project.files[mainFilePath];
  let contents;
  if (aot && parseInt(project.dependencies['@angular/compiler'].version, 10) >= 9) {
    contents = mainFile.contents
      .replace(dynamicPlatform, platform)
      .replace(/platformBrowserDynamic\(\)/g, 'platformBrowser()')
      .replace(/platform-browser-dynamic'/g, `platform-browser'`);
  } else {
    contents = mainFile.contents
      .replace(platform, dynamicPlatform)
      .replace(/platformBrowser\(\)/g, 'platformBrowserDynamic()')
      .replace(/platform-browser'/g, `platform-browser-dynamic'`);
  }

  return { key: mainFilePath, contents };
}
