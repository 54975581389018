<div class="p-2">
  <input
    #input
    placeholder="Search across files..."
    type="text"
    (keydown.enter)="search(input.value)"
  />
</div>
<div class="results p-2">
  <ul>
    <li *ngFor="let item of searchItems$ | async">
        <div>{{ item.path }}</div>
        <div>
          <div *ngFor="let match of item.matches">
            <span>{{ match.leftPart }}</span><b>{{ match.match }}</b>
            <span>{{ match.rightPart }}</span>
          </div>
        </div>
    </li>
    <li *ngIf="loading$ | async">Loading...</li>
  </ul>
</div>
