import { Injectable } from '@angular/core';
import {
  Firestore,
  addDoc,
  deleteDoc,
  serverTimestamp,
  collection,
  collectionData,
  doc,
  docData,
  orderBy,
  query,
  updateDoc,
  where,
} from '@angular/fire/firestore';
import { Storage, deleteObject, ref } from '@angular/fire/storage';
import { take } from 'rxjs/operators';
import { DBProject } from './project.service';
import { AuthService } from '@ng-run/auth';

@Injectable({
  providedIn: 'root',
})
export class LessonsService {
  constructor(private afs: Firestore, private storage: Storage, private auth: AuthService) {}

  private lessonsCollection = collection(this.afs, 'lessons');

  save(lesson: DBLesson): Promise<any> {
    const timestamp = serverTimestamp();

    lesson.updatedAt = timestamp;
    if (!lesson.id) {
      lesson.createdAt = timestamp;
    }

    return (lesson.id
      ? updateDoc(doc(this.afs, '/lessons/' + lesson.id), { ...lesson }).then(() => lesson.id)
      : addDoc(this.lessonsCollection, lesson).then((p) => p.id)
    ).then((projectId) => {
      return {
        id: projectId,
        authorId: lesson.authorId,
        author: lesson.author,
      };
    });
  }

  getAllLessons() {
    return collectionData(
      query(
        this.lessonsCollection,
        where('authorId', '==', this.auth.user ? this.auth.user.uid : ''),
        orderBy('createdAt', 'desc')
      )
    );
  }

  loadFromFirestore(id: string): any {
    return docData(doc(this.afs, `/lessons/${id}`))
      .pipe(take(1))
      .toPromise();
  }

  deleteLesson(lesson: Partial<DBLesson>) {
    return Promise.all([
      deleteDoc(doc(this.afs, `/lessons/${lesson.id}`)),
      deleteObject(ref(this.storage, `lessons/${this.auth.user.uid}/${lesson.recordingFileName}.stream`)),
      deleteObject(ref(this.storage, `lessons/${this.auth.user.uid}/${lesson.recordingFileName}.webm`)),
    ]);
  }
}

export interface DBLesson extends DBProject {
  recordingFileName?: string;
}
