<div class="flex-y-center title p-2" *ngIf="project$ | async as vm">
  <div *ngIf="!(editMode$ | async); else editTmpl" class="flex-y-center flex-1">
    {{ vm.title }}
    <ng-run-icon class="icon-edit ml-2" name="pencil" *ngIf="canEdit$ | async" (click)="editTitle()"></ng-run-icon>
  </div>
  <ng-template #editTmpl>
    <input
      #editTitleControl
      class="solution-tree-item__input"
      [value]="vm.title"
      (keyup.enter)="updateTitle()"
      (keydown.escape)="editMode$.next(false)"
      autocomplete="off"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="false"
    />
    <div class="solution-tree-item__blocker" (click)="editMode$.next(false)"></div>
    <div class="solution-tree-item__error" *ngIf="error$ | async as error">{{ error }}</div>
  </ng-template>
  <div class="icon-settings-btn trigger" ng-run-tooltip="App settings" (click)="settingsOpened$.next(!settingsOpened$.value)">
    <ng-run-icon class="icon-settings" name="settings" height="16" width="16"></ng-run-icon>
  </div>
</div>

<ng-run-solution-settings *ngIf="settingsOpened$ | async"></ng-run-solution-settings>
