import { load } from '../../../loader/editor.loader';
import { PluginConsumer } from '../../plugin-consumer';

export function plugIn(editor: monaco.editor.IStandaloneCodeEditor, self: PluginConsumer) {
  if (self.mode === 'typescript') {
    load(['vs/editor/standalone/browser/standaloneServices']).then(ext => {
      const codeEditor = ext.StaticServices.codeEditorService.get();
      codeEditor.openCodeEditor = ({ options, resource }) => {
        const path = resource.path.startsWith('src/') ? resource.path.slice(4) : resource.path;
        if (path !== self.openedFile) {
          self.projectStore.selectFile(path);
        }
        setTimeout(() => {
          const selection = options.selection;
          if (selection) {
            if ('number' === typeof selection.endLineNumber && 'number' === typeof selection.endColumn) {
              editor.setSelection(selection);
              editor.revealRangeInCenter(selection, 1);
            } else {
              const r = {
                lineNumber: selection.startLineNumber,
                column: selection.startColumn,
              };
              editor.setPosition(r);
              editor.revealPositionInCenter(r, 1);
            }
          }
        });

        return Promise.resolve();
      };
    });
  }

  if (self.mode === 'html') {
    let definitionTimeout;
    editor.onMouseUp((e: any) => {
      if (!e.event.ctrlKey) {
        return;
      }

      clearTimeout(definitionTimeout);
      definitionTimeout = setTimeout(() => {
        const model = editor.getModel();

        const offset = model.getOffsetAt(e.target.position);
        monaco.languages.typescript
          .getTypeScriptWorker()
          .then(worker => worker(''))
          .then(proxy => proxy.getTemplateDefinitionAt(model.uri.toString(), offset))
          .then(res => {
            if (res && res.length) {
              const { fileName, span } = res[0];
              const filePath = fileName.slice(7);

              if (self.projectStore.state.files[filePath]) {
                self.editorWorker.inspect$.next({ filePath, offset: span.start });
              }
            }
          });
      }, 400);
    });
  }
}
