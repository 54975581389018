import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AuthService } from '@ng-run/auth';

@Component({
  selector: 'ng-run-user-panel',
  templateUrl: './user-panel.component.html',
  styleUrls: ['./user-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserPanelComponent {
  user$ = this.auth.user$;

  constructor(private auth: AuthService) {}

  login() {
    this.auth.signInWithGithub().then();
  }

  logout() {
    this.auth.signOut();
  }
}
