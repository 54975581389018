import { Attribute, Directive, ViewContainerRef } from '@angular/core';
import { ContainerRegistry } from '../services/container.registry';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'ng-template[ng-run-container]',
})
export class ContainerDirective {
  constructor(
    @Attribute('ng-run-container') private name: string,
    private containerRegistry: ContainerRegistry,
    private vcRef: ViewContainerRef
  ) {
    this.containerRegistry.registerContainer(name, vcRef);
  }
}
