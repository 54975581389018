import { NgModule } from '@angular/core';

import { SharedModule } from '@ng-run/shared';

import { SolutionExplorerComponent } from './solution-explorer/solution-explorer.component';
import { SolutionTreeComponent } from './solution-tree/solution-tree.component';
import { SolutionTreeItemComponent } from './solution-tree-item/solution-tree-item.component';
import { SolutionTitleComponent } from './solution-title/solution-title.component';
import { SolutionSettingsComponent } from './solution-settings/solution-settings.component';

@NgModule({
  imports: [SharedModule],
  declarations: [
    SolutionTreeItemComponent,
    SolutionTreeComponent,
    SolutionExplorerComponent,
    SolutionTitleComponent,
    SolutionSettingsComponent,
  ],
  exports: [SolutionExplorerComponent, SolutionTitleComponent],
})
export class SolutionExplorerModule {}
