import { defer, Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

export function updateQueryStringParameter(key, value) {
  const uri = window.location.href;
  const regExp = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
  const separator = uri.indexOf('?') !== -1 ? '&' : '?';
  const result = uri.match(regExp)
    ? uri.replace(regExp, '$1' + key + '=' + value + '$2')
    : uri + separator + key + '=' + value;

  return result.replace(window.location.origin, '');
}

export function removeURLParameter(parameter) {
  const url = window.location.href;
  let urlparts = url.split('?');
  let result = url;
  if (urlparts.length >= 2) {
    let prefix = encodeURIComponent(parameter) + '=';
    let pars = urlparts[1].split(/[&;]/g);

    // reverse iteration as may be destructive
    for (let i = pars.length; i-- > 0; ) {
      // idiom for string.startsWith
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }

    result = urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
  }

  return result.replace(window.location.origin, '');
}

export function getParameterByName(name, url?) {
  if (!url) {
    url = window.location.href;
  }
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function timeSince(date) {
  if (date.toDate) {
    date = date.toDate();
  }
  if (typeof date !== 'object') {
    date = new Date(date);
  }

  const seconds = Math.floor(((new Date() as any) - date) / 1000);
  let intervalType;

  let interval = Math.floor(seconds / 31536000);
  if (interval >= 1) {
    intervalType = 'year';
  } else {
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
      intervalType = 'month';
    } else {
      interval = Math.floor(seconds / 86400);
      if (interval >= 1) {
        intervalType = 'day';
      } else {
        interval = Math.floor(seconds / 3600);
        if (interval >= 1) {
          intervalType = 'hour';
        } else {
          interval = Math.floor(seconds / 60);
          if (interval >= 1) {
            intervalType = 'minute';
          } else {
            interval = seconds;
            intervalType = 'second';
          }
        }
      }
    }
  }

  if (interval > 1 || interval === 0) {
    intervalType += 's';
  }

  if (interval < 0) {
    interval = 0;
  }

  return interval + ' ' + intervalType + ' ago';
}

export const log = <T>(source: Observable<T>, name: string) =>
  defer(() => {
    console.log(`${name}: subscribed`);
    return source.pipe(
      tap({
        next: value => {
          console.log(`${name}: ${value}`);
        },
        complete: () => {
          console.log(`${name}: complete`);
        },
      }),
      finalize(() => {
        console.log(`${name}: unsubscribed`);
      })
    );
  });

export function debounce(delay: number = 300): MethodDecorator {
  return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
    let timeout = null;

    const original = descriptor.value;

    function valueHandler(...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => original.apply(this, args), delay);
    }

    valueHandler.cancel = () => {
      clearTimeout(timeout);
    };

    descriptor.value = valueHandler;

    return descriptor;
  };
}

export function newId() {
  return (
    '_' +
    Math.random()
      .toString(36)
      .substr(2, 9)
  );
}

function byteToHex(byte: number): string {
  return `0${byte.toString(16)}`.slice(-2);
}

export async function generateRandomID() {
  const arr = new Uint8Array(10);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, byteToHex).join('');
}

let uniqueId = 1;

export class PromisedWorker {
  private messageHandlers: {
    [key: string]: (result: any) => any;
  } = {};

  constructor(public instance: Worker) {
    instance.addEventListener('message', this.onMessage);
  }

  on(type: string, callback) {
    this.messageHandlers[type] = callback;
    return this;
  }

  onMessage = ({ data }: MessageEvent) => {
    const [messageId, result] = data.type ? [data.type, data] : data;

    if (messageId.toString().startsWith('worker_')) {
      this.messageHandlers[data[1].type](data[1].payload).then(res => {
        const messageToSend = [data[0], res];

        this.instance.postMessage(messageToSend);
      });
    }

    const handler = this.messageHandlers[messageId];
    if (!handler) {
      return;
    }

    handler(result);
    if (Array.isArray(data)) {
      delete this.messageHandlers[messageId];
    }
  };

  postMessage(message: { type: string; payload: any }): Promise<any> {
    const id = uniqueId++;
    const messageToSend = [id, message];

    return new Promise(resolve => {
      this.messageHandlers[id] = resolve;

      this.instance.postMessage(messageToSend);
    });
  }
}
