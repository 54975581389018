import { Component, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'ng-run-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss'],
})
export class DropdownMenuComponent {
  @Input() showArrow = false;

  @Input() align: 'left' | 'right' | 'top' = 'left';

  @Output() opened = new BehaviorSubject(false);

  top: number;

  left: number;

  toggle(target: HTMLDivElement) {
    const { top, left } = target.getBoundingClientRect();
    this.top = top + target.clientHeight + 5;
    this.left = left;
    this.opened.next(!this.opened.value);
  }

  close() {
    this.opened.next(false);
  }
}
