<ng-container *ngIf="schematic$ | async as options">
  <div class="cli-generator-input">
    <input
      placeholder="Type {{ options.command }} name..."
      type="text"
      #cliInput
      (keydown.enter)="runSchematics(cliInput.value)"
      (keydown.escape)="closeWindow()"
    />
  </div>
  <div class="fake-bg" (click)="closeWindow()"></div>
</ng-container>
