<div class="d-flex align-items-center no-wrap mb-2 setting">
  Ivy AOT
  <ng-run-checkbox
    class="ml-auto"
    *ngIf="(ngVersion$ | async) > 8; else notSupportedTmpl"
    [checked]="aotEnabled$ | async"
    (change)="toggleAot()"
  ></ng-run-checkbox>
  <ng-template #notSupportedTmpl>
    <span class="ml-auto">Only for ng >=9</span>
  </ng-template>
</div>
<div class="d-flex align-items-center no-wrap mb-2 setting">
  Vim mode
  <ng-run-checkbox
    class="ml-auto"
    [checked]="vimEnabled$ | async"
    (change)="toggleVim()"
  ></ng-run-checkbox>
</div>
<div class="d-flex align-items-center no-wrap mb-2 setting">
  Recording
  <ng-run-checkbox
    class="ml-auto"
    [checked]="recording$ | async"
    (change)="toggleRecording()"
  ></ng-run-checkbox>
</div>
<label class="d-flex align-items-center no-wrap setting">
  Reload mode
  <select [disabled]="liveService.roomId$ | async" class="ml-auto" [value]="reloadMode$ | async" (change)="setReloadMode($event)">
    <option value="edit">Edit</option>
    <option value="save">Save</option>
  </select>
</label>
