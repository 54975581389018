<div class="editor-header flex-y-center">
  <div
    *ngIf="openedFile"
    class="editor-tab-title"
    (click)="setCurrentFile()"
    [class.editor-tab-title--active]="(selectedFile$ | async) === openedFile"
    [class.editor-tab-title--dirty]="isDirty$ | async"
  >
    {{ openedFile }}
  </div>
  <div class="editor-actions ml-auto">
    <div
      class="editor-action editor-action-screenshot"
      ng-run-tooltip="Toggle screenshot mode"
      [class.editor-action-screenshot--enabled]="isScreenShotMode$ | async"
      (click)="makeScreenShot()"
    >
      <svg class="icon-screenshot" width="30" height="30">
        <use xlink:href="#icon-screenshot"></use>
      </svg>
    </div>
    <ng-run-dropdown-menu class="ml-2" [showArrow]="true">
      <ng-container menuTrigger>Tips</ng-container>
      <ng-container>
        <div class="editor-action-option"><b>Alt + Shift + F</b> - Auto-format</div>
        <div class="editor-action-option"><b>Ctrl + Alt + Shift + P</b> - Prettier format</div>
        <ng-container *ngIf="mode === 'html'; else tips">
          <div class="editor-action-option"><b>Emmet</b> is enabled.</div>
          <div class="editor-action-option editor-action-option--new"><b>Angular language service</b> is enabled.</div>
        </ng-container>

        <ng-template #tips>
          <div class="editor-action-option"><b>F2</b> - Next highlighted error</div>
          <div class="editor-action-option"><b>Alt + Enter</b> - Auto-import current symbol</div>
          <div class="editor-action-option"><b>Ctrl + Alt + C</b> - console.log($0)</div>
        </ng-template>
        <div class="editor-action-option"><b>Ctrl + P</b> - Quick open recent files</div>
      </ng-container>
    </ng-run-dropdown-menu>

    <div class="editor-action" title="Shift-Alt-F" (click)="formatCode()">
      <svg class="icon-format" width="30">
        <use xlink:href="#icon-format"></use>
      </svg>
      Format
    </div>

    <ng-run-dropdown-menu class="ml-2" align="right">
      <ng-container menuTrigger>
        <svg class="icon-settings" width="16" height="16">
          <use xlink:href="#icon-settings"></use></svg
        >&nbsp; .prettierrc
      </ng-container>
      <textarea
        [value]="prettierConfig$ | async | json"
        class="prettier-config"
        (input)="updatePrettierConfig($any($event.target).value)"
      ></textarea>
    </ng-run-dropdown-menu>
  </div>
</div>
