import { ComponentFactoryResolver, Injectable, Injector } from '@angular/core';
import { NotificationComponent } from './notification.component';

export interface NotificationPayload {
  type: 'success' | 'error' | 'info';
  message: string;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  container: HTMLDivElement;

  constructor(private resolver: ComponentFactoryResolver, private injector: Injector) {}

  notify(payload: NotificationPayload, timeout = 2_000) {
    const factory = this.resolver.resolveComponentFactory(NotificationComponent);
    const componentRef = factory.create(this.injector);
    componentRef.instance.message = payload.message;
    componentRef.instance.type = payload.type;
    componentRef.changeDetectorRef.detectChanges();
    const hostEl = componentRef.location.nativeElement;

    const container = this.getContainer();
    container.appendChild(hostEl);
    setTimeout(() => {
      componentRef.destroy();
      hostEl.parentNode.removeChild(hostEl);
    }, timeout);
  }

  getContainer() {
    if (this.container) {
      return this.container;
    }

    const container = document.createElement('div');
    container.className = 'notification-container';
    document.body.appendChild(container);
    this.container = container;

    return container;
  }
}
