import { inject, Injectable } from '@angular/core';
import {
  collection,
  collectionData,
  Firestore,
  limit,
  orderBy,
  query,
  where,
  doc,
  docData,
  deleteDoc,
  updateDoc,
  addDoc,
  serverTimestamp,
  DocumentData,
} from '@angular/fire/firestore';
import { take } from 'rxjs/operators';

import { AuthService } from '@ng-run/auth';
import { environment } from '@ng-run/env';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  private afs: Firestore = inject(Firestore);

  constructor(private auth: AuthService) {}

  private projectsCollection = collection(this.afs, 'projects');

  save(project: DBProject): Promise<any> {
    const timestamp = serverTimestamp();

    project.updatedAt = timestamp;
    if (!project.id) {
      project.createdAt = timestamp;
    }

    return (project.id
      ? updateDoc(doc(this.afs, `/projects/${project.id}`), { ...project }).then(() => project.id)
      : addDoc(this.projectsCollection, project).then((p) => p.id)
    ).then((projectId) => {
      return {
        id: projectId,
        authorId: project.authorId,
        author: project.author,
      };
    });
  }

  loadFromFirestore(id: string): any {
    return docData(doc(this.afs, `/projects/${id}`))
      .pipe(take(1))
      .toPromise();
  }

  loadFromGithub(path: string) {
    return fetch(`${environment.API_URL}github?path=${path}`)
      .then((res) => res.json())
      .catch((e) => {
        alert('Sorry, something went wrong!');
      });
  }

  loadFromStore(path: string) {
    return fetch(`${environment.API_URL}store${path}`)
      .then((res) => res.json())
      .catch((e) => {
        alert('Sorry, something went wrong!');
      });
  }

  getLastProjects(): Observable<DocumentData[]> {
    return collectionData(
      query(
        this.projectsCollection,
        where('authorId', '==', this.auth.user ? this.auth.user.uid : ''),
        orderBy('createdAt', 'desc'),
        limit(5)
      )
    ).pipe(take(1));
  }

  getVersions(packageName: string) {
    return fetch(`${environment.API_URL}versions/${packageName}`).then((res) => res.json());
  }

  deleteProject(id: string) {
    return deleteDoc(doc(this.afs, `/projects/${id}`));
  }
}

export interface DBProject {
  id?: string;
  title: string;
  authorId: string;
  author: {
    displayName: string;
    photoURL: string;
  };
  manifest: any;
  selectedFile: string;
  files: string;
  dependencies: any;
  externalResources: string;
  createdAt?: any;
  updatedAt?: any;
  aot?: boolean;
  compilerOptions?: any;
  target?: string;
  nonNg?: boolean;
}
