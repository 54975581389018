import { Component, ElementRef, ViewChild, ɵdetectChanges as detectChanges } from '@angular/core';
import { FileItem, ProjectStore } from '@ng-run/playground-store';
import lineColumn from 'line-column';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { concatMap, scan, tap } from 'rxjs/operators';
import { TextEdit } from 'vscode-languageserver-types';
import del = TextEdit.del;

@Component({
  selector: 'ng-run-search-dialog',
  templateUrl: './search-dialog.component.html',
  styleUrls: ['./search-dialog.component.scss'],
})
export class SearchDialogComponent {
  @ViewChild('input')
  set input(inputEl: ElementRef<HTMLInputElement>) {
    if (inputEl) {
      inputEl.nativeElement.focus();
    }
  }

  found$ = new Subject<SearchResultItem>();

  loading$ = new BehaviorSubject(false);

  searchItems$: Observable<SearchResultItem[]> = this.found$.pipe(
    tap((res) => console.log(res)),
    scan((acc, curr) => acc.concat(curr), []),
    tap((res) => console.log(res))
  );

  constructor(private projectStore: ProjectStore) {}

  async search(value: string) {
    const files = this.projectStore.state.files;
    const matchCase = false;
    const query = new RegExp(escapeRegExp(value), `g${matchCase ? '' : 'i'}`);

    this.loading$.next(true);

    await Promise.all(
      Object.keys(files).map(async (key) => {
        const result = getSearchResultFromFile(query, files[key]);
        if (result.matches.length) {
          this.found$.next(result);
        }
      })
    );

    this.loading$.next(false);
  }
}

// https://stackoverflow.com/questions/3446170/escape-string-for-use-in-javascript-regex#answer-6969486
function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

function getSearchResultFromFile(queryRe: RegExp, { fullPath, contents }: FileItem): SearchResultItem {
  const lineColumnFinder = lineColumn(contents);

  let matchResult;
  const next = () => (matchResult = queryRe.exec(contents));

  const result: SearchResultItem = {
    path: fullPath,
    matches: [],
  };
  next();
  while (matchResult) {
    const { 0: match, index } = matchResult;

    const prevEndOfLine = contents.lastIndexOf('\n', index);
    const leftPart = contents
      .slice(prevEndOfLine > -1 ? prevEndOfLine + 1 : 0, index)
      .slice(-20)
      .trimLeft();

    const endOfLine = contents.indexOf('\n', index);
    const rightPart = contents
      .slice(index + match.length, endOfLine > -1 ? endOfLine : contents.length)
      .slice(0, 20)
      .trimRight();

    const { line, col } = lineColumnFinder.fromIndex(index);
    const res = [leftPart, match, rightPart];
    result.matches.push({
      line,
      col,
      leftPart,
      match,
      rightPart,
    });

    next();
  }

  return result;
}

interface SearchResultItem {
  path: string;
  matches: SearchMatch[];
}

interface SearchMatch {
  line: number;
  col: number;
  leftPart: string;
  match: string;
  rightPart: string;
}
