export function initMacros(editor: monaco.editor.IStandaloneCodeEditor) {
  editor.addAction({
    id: 'console-autocomplete',
    label: 'console.log($0)',
    // An optional array of keybindings for the action.
    keybindings: [
      // tslint:disable-next-line
      monaco.KeyMod.CtrlCmd | monaco.KeyMod.Alt | monaco.KeyCode.KEY_C, // ,
      // chord
      // monaco.KeyMod.chord(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_K, monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_M)
    ],
    // A precondition for this action.
    precondition: null,
    // A rule to evaluate on top of the precondition in order to dispatch the keybindings.
    keybindingContext: null,
    contextMenuGroupId: 'navigation',
    contextMenuOrder: 1.5,

    run: ed => {
      let position = editor.getPosition();
      const lineContent = ed.getModel().getLineContent(position.lineNumber);
      const startCol = lineContent.search(/\S|$/);

      const word = lineContent.substring(startCol);
      const deleteRange = new monaco.Range(position.lineNumber, startCol + 1, position.lineNumber, position.column);

      editor.executeEdits('', [
        {
          range: deleteRange,
          text: '',
          forceMoveMarkers: true,
        },
      ]);
      position = editor.getPosition();

      const range = new monaco.Range(position.lineNumber, startCol + 1, position.lineNumber, startCol + 1);

      const text = `console.log(${word});`;
      editor.executeEdits('', [{ range: range, text, forceMoveMarkers: true }]);
    },
  });
}
