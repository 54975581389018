const formatDefaults: monaco.languages.html.HTMLFormatConfiguration = {
  tabSize: 4,
  insertSpaces: false,
  wrapLineLength: 120,
  unformatted:
    'default": "a, abbr, acronym, b, bdo, big, br, button, cite, code, dfn, em, i, img, input, kbd, label, map, object, q, samp, select, small, span, strong, sub, sup, textarea, tt, var',
  contentUnformatted: 'pre',
  indentInnerHtml: false,
  preserveNewLines: true,
  maxPreserveNewLines: null,
  indentHandlebars: false,
  endWithNewline: false,
  extraLiners: 'head, body, /html',
  wrapAttributes: 'auto',
};

const options: monaco.languages.html.Options = {
  format: formatDefaults,
  suggest: { html5: true, angular1: true, ionic: true },
};

const languageId = 'html';
let htmlService;
let ls;

export class InlineHtmlCompletion implements monaco.languages.CompletionItemProvider {
  matched = false;

  private _languageService: any;

  constructor() {
    __non_webpack_require__(['vs/language/html/htmlWorker'], () => {
      __non_webpack_require__(['vscode-html-languageservice'], languageservice => {
        htmlService = languageservice;
        this._languageService = htmlService.getLanguageService();
        __non_webpack_require__(['vscode-languageserver-types'], languageserver => {
          ls = languageserver;
        });
      });
    });
  }

  get triggerCharacters(): string[] {
    return ['.', ':', '<', '"', '=', '/'];
  }

  provideCompletionItems(
    model: monaco.editor.IReadOnlyModel,
    position: monaco.Position,
    context: monaco.languages.CompletionContext,
    token: monaco.CancellationToken
  ): monaco.Thenable<monaco.languages.CompletionList> {
    this.matched = false;
    const templateRegExp = /(template\s*:\s*`)([^`]*)`/g;
    const text = model.getValue();
    const pos = model.getOffsetAt(position);
    let match = null;

    let shift;
    while ((match = templateRegExp.exec(text)) !== null) {
      if (pos > match.index + match[1].length && pos < match.index + match[0].length) {
        this.matched = true;
        shift = model.getPositionAt(match.index + match[1].length);
        return this.doComplete(
          'd' + Date.now(),
          match[2],
          findLineAtPosition(match[2], pos - match[1].length - match.index)
        ).then(info => {
          if (!info) {
            return;
          }
          const items: monaco.languages.CompletionItem[] = info.items.map(entry => {
            const wordInfo = model.getWordUntilPosition(position);
            const wordRange = new monaco.Range(
              position.lineNumber,
              wordInfo.startColumn,
              position.lineNumber,
              wordInfo.endColumn
            );

            const item: monaco.languages.CompletionItem = {
              label: entry.label,
              insertText: entry.insertText,
              sortText: entry.sortText,
              filterText: entry.filterText,
              documentation: entry.documentation,
              detail: entry.detail,
              range: wordRange,
              kind: toCompletionItemKind(entry.kind),
            };
            if (entry.textEdit) {
              item.range = toRange(entry.textEdit.range, shift);
              item.insertText = entry.textEdit.newText;
            }

            return item;
          });

          return {
            incomplete: info.isIncomplete,
            suggestions: items,
          };
        });
      }
    }

    return Promise.resolve({
      incomplete: false,
      suggestions: [],
    });
  }

  doComplete(uri: string, text, position) {
    const document = this._getTextDocument(uri, text);
    const htmlDocument = this._languageService.parseHTMLDocument(document);
    return Promise.resolve(
      this._languageService.doComplete(document, position, htmlDocument, options && options.suggest)
    );
  }

  format(text: string) {
    const document = this._getTextDocument('', text);
    return this._languageService.format(document, '', options.format)[0].newText;
  }

  private _getTextDocument(uri: string, text: string) {
    return ls.TextDocument.create('', languageId, '', text);
  }
}

function findLineAtPosition(str: string, offset: number) {
  const first = str.substring(0, offset);
  const firstNewLine = first.lastIndexOf('\n');

  return {
    line: first.split('\n').length - 1,
    character: offset - firstNewLine - 1,
  };
}

function toCompletionItemKind(kind: number): monaco.languages.CompletionItemKind {
  const mItemKind = monaco.languages.CompletionItemKind;

  switch (kind) {
    case ls.CompletionItemKind.Text:
      return mItemKind.Text;
    case ls.CompletionItemKind.Method:
      return mItemKind.Method;
    case ls.CompletionItemKind.Function:
      return mItemKind.Function;
    case ls.CompletionItemKind.Constructor:
      return mItemKind.Constructor;
    case ls.CompletionItemKind.Field:
      return mItemKind.Field;
    case ls.CompletionItemKind.Variable:
      return mItemKind.Variable;
    case ls.CompletionItemKind.Class:
      return mItemKind.Class;
    case ls.CompletionItemKind.Interface:
      return mItemKind.Interface;
    case ls.CompletionItemKind.Module:
      return mItemKind.Module;
    case ls.CompletionItemKind.Property:
      return mItemKind.Property;
    case ls.CompletionItemKind.Unit:
      return mItemKind.Unit;
    case ls.CompletionItemKind.Value:
      return mItemKind.Value;
    case ls.CompletionItemKind.Enum:
      return mItemKind.Enum;
    case ls.CompletionItemKind.Keyword:
      return mItemKind.Keyword;
    case ls.CompletionItemKind.Snippet:
      return mItemKind.Snippet;
    case ls.CompletionItemKind.Color:
      return mItemKind.Color;
    case ls.CompletionItemKind.File:
      return mItemKind.File;
    case ls.CompletionItemKind.Reference:
      return mItemKind.Reference;
  }
  return mItemKind.Property;
}

function toRange(range: any, shift: monaco.Position): monaco.Range {
  if (!range) {
    return void 0;
  }

  return new monaco.Range(
    shift.lineNumber + range.start.line,
    range.start.line === 0 ? shift.column + range.start.character : range.start.character + 1,
    shift.lineNumber + range.end.line,
    range.end.line === 0 ? shift.column + range.end.character : range.end.character + 1
  );
}
