<ng-content></ng-content>
<ng-template ngFor [ngForOf]="areas$ | async" let-area let-index="index" let-last="last">
  <div
    *ngIf="!last"
    class="separator separator--{{ direction }}"
    [style.width.px]="direction === 'horizontal' ? separatorSize : 'auto'"
    [style.height.px]="direction === 'horizontal' ? 'auto' : separatorSize"
    [style.order]="index * 2 + 1"
    (mousedown)="onSeparatorMouseDown($event, index)"
    (touchstart)="onSeparatorMouseDown($event, index)"
  ></div>
</ng-template>
