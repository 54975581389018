<ng-container *ngIf="{ opened: opened | async } as vm">
  <div
    class="flex-y-center trigger h-100"
    [class.trigger--with-arrow]="showArrow"
    [class.trigger--opened]="vm.opened"
    (click)="toggle(target)"
    #target
  >
    <ng-content select="[menuTrigger]"></ng-content>
  </div>
  <div
    class="menu"
    [class.menu--opened]="vm.opened"
    [class.menu--right]="align === 'right'"
    [class.menu--top]="align === 'top'"
    [style.top.px]="align === 'left' ? top : null"
    [style.left.px]="align === 'left' ? left : null"
  >
    <ng-content></ng-content>
  </div>
  <div class="menu-backdrop" *ngIf="vm.opened" (click)="close()"></div>
</ng-container>
