import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DropdownMenuComponent } from './components/dropdown-menu/dropdown-menu.component';
import { IconComponent } from './components/icon/icon.component';
import { SplitterComponent } from './components/splitter/splitter.component';
import { SplitAreaDirective } from './components/splitter/split-area.directive';

import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { TogglerComponent } from './components/toggler/toggler.component';
import { LoadingComponent } from './components/loading/loading.component';
import { NotificationComponent } from './services/notification/notification.component';

import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { TooltipContentComponent, TooltipDirective } from './directives/tooltip.directive';
import { ContainerDirective } from './directives/container.directive';

const SHARED_MODULES = [CommonModule];

const SHARED_COMPONENTS = [
  DropdownMenuComponent,
  IconComponent,
  SplitterComponent,
  CheckboxComponent,
  TogglerComponent,
  LoadingComponent,
  NotificationComponent,
  TooltipContentComponent,
];

const SHARED_DIRECTIVES = [SplitAreaDirective, TooltipDirective, ContainerDirective];

const SHARED_PIPES = [SafeUrlPipe];

@NgModule({
  declarations: [SHARED_COMPONENTS, SHARED_DIRECTIVES, SHARED_PIPES],
  imports: [SHARED_MODULES],
  exports: [SHARED_MODULES, SHARED_COMPONENTS, SHARED_DIRECTIVES, SHARED_PIPES],
})
export class SharedModule {}
