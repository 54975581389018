import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
} from '@angular/core';

const OFFSET = 5;
const SHIFT = 5;
const shift = {
  above: SHIFT,
  below: -SHIFT,
};

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[ng-run-tooltip]',
})
export class TooltipDirective implements OnChanges, OnDestroy {
  @Input('ng-run-tooltip')
  content: string;

  @Input('ng-run-tooltip-position')
  position: 'above' | 'below' = 'below';

  private componentRef: ComponentRef<TooltipContentComponent>;

  private cachedPosition;

  ngOnChanges() {
    if (this.componentRef) {
      this.componentRef.instance.content = this.content;
      this.componentRef.changeDetectorRef.detectChanges();
    }
  }

  @HostListener('mouseenter')
  showTooltip() {
    if (!this.componentRef) {
      const factory = this.componentResolver.resolveComponentFactory(TooltipContentComponent);
      this.componentRef = factory.create(this.injector);
      this.componentRef.instance.content = this.content;
      this.componentRef.changeDetectorRef.detectChanges();
    }

    const host = this.componentRef.location.nativeElement;
    host.dataset.position = this.position;
    if (!host.parentNode) {
      document.body.appendChild(host);
    }

    const { top, bottom, left, width } = this.elRef.nativeElement.getBoundingClientRect();
    const leftShift = host.getBoundingClientRect().width / 2;

    const tx = left + width / 2 - leftShift;
    const ty = this.position === 'above' ? top - OFFSET - host.offsetHeight : bottom + OFFSET;
    host.style.transform = `translate3d(${tx}px, ${ty + shift[this.position]}px, 0)`;
    host.getBoundingClientRect();
    host.dataset.state = 'visible';
    host.style.transform = `translate3d(${tx}px, ${ty}px, 0)`;
    this.cachedPosition = [tx, ty];
  }

  @HostListener('mouseleave')
  onLeave() {
    this.hideTooltip();
  }

  constructor(
    private componentResolver: ComponentFactoryResolver,
    private injector: Injector,
    private elRef: ElementRef<HTMLElement>
  ) {}

  private hideTooltip() {
    if (!this.componentRef) {
      return;
    }

    const host = this.componentRef.location.nativeElement;
    host.dataset.state = 'hidden';
    const [tx, ty] = this.cachedPosition;
    host.style.transform = `translate3d(${tx}px, ${ty + shift[this.position]}px, 0)`;
  }

  ngOnDestroy() {
    if (this.componentRef) {
      const host = this.componentRef.location.nativeElement as HTMLElement;
      if (host.parentNode) {
        host.parentNode.removeChild(host);
      }
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }
}

@Component({
  selector: 'ng-run-tooltip',
  template: `
    <div class="tooltip-content">{{ content }}</div>
  `,
  styleUrls: ['./tooltip-content.component.scss'],
})
export class TooltipContentComponent {
  @Input() content: string;
}
