import { PromisedWorker } from '@ng-run/utils';
import { ProjectStore } from '@ng-run/playground-store';
import Thenable = monaco.Thenable;
import CancellationToken = monaco.CancellationToken;
import Position = monaco.Position;

export class PathCompletionProvider implements monaco.languages.CompletionItemProvider {
  triggerCharacters: string[] = ['.', '/', '@'];

  constructor(private worker: PromisedWorker, private projectStore: ProjectStore) {}

  provideCompletionItems(
    model: monaco.editor.IReadOnlyModel,
    position: Position,
    context: monaco.languages.CompletionContext,
    token: CancellationToken
  ): Thenable<monaco.languages.CompletionList> {
    const offset = model.getOffsetAt(position);
    const contents = model.getValue();
    return this.worker
      .postMessage({
        type: 'GET_TOKEN_AT_POSITION',
        payload: { contents, offset },
      })
      .then(({ node, isInImport, text }) => {
        if (!isInImport) {
          return;
        }

        const leadingText = text.match(/^\s+['|"]/g);
        const trailingText = text.match(/['|"]$/g);
        const from = leadingText ? node.pos + leadingText[0].length : node.pos;
        const to = trailingText ? node.end - trailingText[0].length : node.end;
        if (from <= offset && offset <= to) {
          const files = this.projectStore.state.files;
          const deps = this.projectStore.state.dependencies;
          const filePaths = Object.keys(files)
            .filter(key => files[key].type === 'file' && key.endsWith('.ts') && key !== model.uri.path)
            .map(x => {
              const dest = getRelativePath(model.uri.path.slice(4), x);
              return {
                label: dest,
                insertText: dest.slice(0, -3),
              };
            });
          const depsPaths = Object.keys(deps)
            .filter(x => !['whatwg-fetch', 'zone.js', 'tslib', 'typescript'].includes(x))
            .map(x => ({ label: x, insertText: x }));

          const word = contents.substring(from, offset);

          const suggestions = [...filePaths, ...depsPaths].map(x => {
            return {
              label: x.label,
              insertText: x.insertText,
              kind: monaco.languages.CompletionItemKind.File,
              range: {
                startLineNumber: position.lineNumber,
                startColumn: position.column - word.length,
                endLineNumber: position.lineNumber,
                endColumn: position.column,
              } as any,
            };
          });

          return {
            suggestions,
          };
        }
        return {
          suggestions: [],
        };
      });
  }
}

function getRelativePath(source: string, target: string) {
  const sep = '/';
  const targetArr = target.split(sep);
  const sourceArr = source.split(sep);

  sourceArr.pop();

  const targetFileName = targetArr.pop();
  const targetPath = targetArr.join(sep);
  let relativePath = '';

  if (sourceArr.join(sep) === targetPath) {
    return './' + targetFileName;
  }

  while (targetPath.indexOf(sourceArr.join(sep)) === -1) {
    sourceArr.pop();
    relativePath += '..' + sep;
  }

  const relPathArr = targetArr.slice(sourceArr.length);
  if (relPathArr.length) {
    relativePath += relPathArr.join(sep) + sep;
  }

  const prefix = relativePath.indexOf('..') === 0 ? '' : './';
  return prefix + relativePath + targetFileName;
}
