<ng-run-dropdown-menu *ngIf="activeTheme$ | async as activeTheme" #menu align="right" ng-run-tooltip="Theme Picker">
  <ng-container menuTrigger>
    <ng-run-icon class="mr-1" name="fill"></ng-run-icon>
    <span class="active-theme">{{ activeTheme.label }}</span>
  </ng-container>
  <div
    class="menu-item"
    [class.menu-item--active]="theme.key === activeTheme.key"
    *ngFor="let theme of themes"
    (click)="applyTheme(theme); menu.close()"
  >
    {{ theme.label }}
  </div>
</ng-run-dropdown-menu>
