import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Location } from '@angular/common';

import { NotificationService } from '@ng-run/shared';
import { ProjectStore } from '@ng-run/playground-store';

@Component({
  selector: 'ng-run-solution-explorer',
  templateUrl: './solution-explorer.component.html',
  styleUrls: ['./solution-explorer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SolutionExplorerComponent {
  files$ = this.projectStore.select(state => {
    return Object.keys(state.files).reduce((acc, key) => {
      state.files[key].fullPath = key;
      acc.push(state.files[key]);
      return acc;
    }, []);
  });

  canEdit$ = this.projectStore.canEdit$;

  constructor(
    private projectStore: ProjectStore,
    private notificationService: NotificationService,
    private location: Location
  ) {}

  deleteProject() {
    const project = this.projectStore.state;
    const confirmResult = confirm(`Are you sure you want to delete project: "${project.title}"?`);
    if (!confirmResult) {
      return;
    }

    this.projectStore
      .deleteProject(project.id)
      .then(() => {
        this.notificationService.notify({
          type: 'success',
          message: 'Project has been successfully deleted',
        });
        this.location.go('/');
        location.reload();
      })
      .catch(err => {
        this.notificationService.notify({
          type: 'error',
          message: err.message,
        });
      });
  }
}
