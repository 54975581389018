import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';

import { environment } from '@ng-run/env';
import { PlaygroundStore } from '@ng-run/playground-store';
import { EditorWorker } from '../editor/editor.worker';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'ng-run-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss'],
})
export class PreviewComponent implements AfterViewInit {
  @ViewChild('child', { static: true }) child: ElementRef;

  previewUrl = `${environment.previewUrl}?${environment.hash}`;

  hash = environment.hash;

  url$ = this.playgroundStore.previewUrl$;

  bundleSuccess$ = this.editorWorker.bundleSuccess$;

  error$ = this.editorWorker.errorInfo$.pipe(
    map(res => res && res.constructor === Object && Object.keys(res).length > 1)
  );

  pkgNames$ = this.editorWorker.confirmInstallState$;

  installingPkgNames$ = this.editorWorker.installState$;

  inspectMode$ = new BehaviorSubject(false);

  screenShotMode$ = this.playgroundStore.screenShotMode$;

  constructor(public editorWorker: EditorWorker, private playgroundStore: PlaygroundStore) {}

  ngAfterViewInit() {
    window['preview'] = this.child.nativeElement.contentWindow;
  }

  reload(url?: string) {
    preview.postMessage(
      {
        type: 'RELOAD',
        payload: url,
      },
      '*'
    );
  }

  toggleInspectMode() {
    this.inspectMode$.next(!this.inspectMode$.value);
    preview.postMessage(
      {
        type: 'TOGGLE_INSPECT',
      },
      '*'
    );
  }

  saveScreenShot(container) {
    import('html2canvas')
      .then(res => res.default)
      .then(html2canvas => {
        html2canvas(container, {
          width: container.offsetWidth,
          height: container.offsetHeight,
          scale: 2,
        }).then(canvas => {
          const myImage = canvas.toDataURL();
          const link = document.createElement('a');

          link.download = 'code.png';
          link.href = myImage;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      });
  }
}
