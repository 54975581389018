import { ProjectState, FileItem, FileTypeEnum } from './project.state';

const files: { [key: string]: FileItem } = {
  'index.html': {
    name: 'index.html',
    type: FileTypeEnum.File,
    contents: `<my-app id="app">Loading...</my-app>`,
  },
  app: {
    name: 'app',
    type: FileTypeEnum.Folder,
    contents: '',
  },
  'main.ts': {
    name: 'main.ts',
    type: FileTypeEnum.File,
    contents: `import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

platformBrowserDynamic().bootstrapModule(AppModule);`,
  },
  'app/app.module.ts': {
    name: 'app.module.ts',
    type: FileTypeEnum.File,
    contents: `import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';

@NgModule({
  imports: [
    BrowserModule
  ],
  declarations: [
    AppComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }`,
  },
  'app/app.component.ts': {
    name: 'app.component.ts',
    type: FileTypeEnum.File,
    contents: `import { Component, VERSION } from '@angular/core';

@Component({
  selector: 'my-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  name = 'Angular ' + VERSION.full;
}
`,
  },
  'app/app.component.html': {
    name: 'app.component.html',
    type: FileTypeEnum.File,
    contents: '<h2 class="title">Hello {{name}}</h2>',
  },
  'app/app.component.css': {
    name: 'app.component.css',
    type: FileTypeEnum.File,
    contents: `.title {
  font-family: sans-serif;
}`,
  },
  'styles.css': {
    name: 'styles.css',
    type: FileTypeEnum.File,
    contents: `/* Write your global styles here */`,
  },
};

const dependencies = {
  rxjs: {
    main: './index.js',
    typings: './index.d.ts',
    version: '6.6.0',
  },
  tslib: {
    main: './tslib.js',
    typings: './index.d.ts',
    version: '1.9.3',
    fake: true,
  },
  typescript: {
    loaded: true,
    fake: true,
  },
  'zone.js': {
    main: './dist/zone.js',
    version: '0.10.2',
    loaded: true,
    fake: true,
  },
  'core-js': {
    main: './client/shim.min.js',
    version: '2.4.1',
    loaded: true,
    fake: true,
  },
  'whatwg-fetch': {
    main: 'fetch.js',
    version: '2.0.4',
    fake: true,
  },
};

const ngVersion = '11.0.2';

['platform-browser', 'platform-browser-dynamic', 'core', 'compiler', 'common', 'forms', 'router'].forEach(pkg => {
  dependencies['@angular/' + pkg] = {
    main: `./bundles/${pkg}.umd.js`,
    module: `./esm5/${pkg}.js`,
    typings: `./${pkg}.d.ts`,
    version: ngVersion,
  };
});

export const defaultProject: ProjectState = {
  title: 'Base Project',
  selectedFile: 'app/app.component.ts',
  manifest: {
    indexHtml: 'index.html',
    entry: 'main.ts',
    styles: 'styles.css',
  },
  files,
  dependencies,
  aot: true,
};
