import { ChangeDetectionStrategy, Component, ElementRef, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, merge } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { debounce } from '@ng-run/utils';
import { PlaygroundStore, ProjectStore } from '@ng-run/playground-store';
import { loadEditor, setCompilerOptions } from './loader/editor.loader';
import { MonacoEditorStore } from './monaco.store';
import { EditorWorker } from './editor.worker';
import { PluginConsumer } from './plugins/plugin-consumer';
import { LiveService } from './live/live.service';
import { AuthService } from '@ng-run/auth';
import { ActivatedRoute } from '@angular/router';

const defaultHtmlPath = 'app/app.component.html';
const defaultTsPath = 'app/app.component.ts';

@Component({
  selector: 'ng-run-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorComponent implements OnInit, PluginConsumer {
  @Input() mode: 'html' | 'typescript';

  @ViewChild('editorTarget') editorTarget: ElementRef<HTMLDivElement>;

  @ViewChild('editorBody') editorBody: ElementRef<HTMLDivElement>;

  editor: monaco.editor.IStandaloneCodeEditor;

  openedFile: string;

  cursorPositions = {};

  decorations: any;
  decorationsCache = {};

  projectLoaded$ = this.projectStore.projectLoaded$;

  target$ = this.projectStore.target$;

  openedFile$ = this.projectStore
    .select((state) => state.selectedFile)
    .pipe(
      map((selectedFile) => {
        const isEndsWithHtml = selectedFile.endsWith('.html');
        const project = this.projectStore.state;
        if (this.isHtml && !isEndsWithHtml) {
          selectedFile =
            this.openedFile || (project.files[defaultHtmlPath] ? defaultHtmlPath : project.manifest.indexHtml);
        } else if (!this.isHtml && isEndsWithHtml) {
          selectedFile = this.openedFile || (project.files[defaultTsPath] ? defaultTsPath : project.manifest.entry);
        }

        if (!project.files[selectedFile]) {
          selectedFile = !this.isHtml ? project.manifest.entry : project.manifest.indexHtml;
        }

        return selectedFile;
      }),
      tap((selectedFile) => {
        if (this.openedFile === selectedFile) {
          this.cursorPositions[selectedFile] = this.editor && this.editor.getPosition();
          if (this.projectStore.state.selectedFile === selectedFile) {
            this.focus(selectedFile);
          }
          return;
        }

        this.openFile(selectedFile);
        this.refreshDecorators();
      })
    );

  loading$ = new BehaviorSubject(true);

  isHtml: boolean;

  compilerOptions$ = new BehaviorSubject([]);

  private highlightTimeout;

  constructor(
    public elRef: ElementRef,
    private route: ActivatedRoute,
    public projectStore: ProjectStore,
    public playgroundStore: PlaygroundStore,
    public monacoStore: MonacoEditorStore,
    public editorWorker: EditorWorker,
    private liveService: LiveService,
    public injector: Injector
  ) {}

  async ngOnInit() {
    this.isHtml = this.mode === 'html';

    await loadEditor();
    this.initEditor();
  }

  formatCode() {
    this.editor.getAction('editor.action.formatDocument').run();
  }

  refreshDecorators() {
    if (!this.editor || !this.projectStore.state || this.projectStore.state.selectedFile !== this.openedFile) {
      return;
    }

    const selectedFile = this.openedFile;

    const model = this.editor.getModel();
    const lang = model.getModeId();

    if (!['typescript', 'html'].includes(lang)) {
      return;
    }
    const cache = this.decorationsCache.hasOwnProperty(selectedFile) ? this.decorationsCache[selectedFile] : [];
    this.decorations = this.editor.deltaDecorations(this.decorations ? this.decorations : [], cache);

    const error = this.editorWorker.errorInfo$.getValue();
    if (!error || (error.file && error.file !== this.openedFile)) {
      monaco.editor.setModelMarkers(model, null, []);
      return;
    }

    let line;
    if (error.startPos) {
      const startPos = model.getPositionAt(error.startPos);
      const endPos = model.getPositionAt(error.endPos);
      line = startPos.lineNumber;
      error.start = {
        line: startPos.lineNumber,
        ch: startPos.column,
      };
      error.end = {
        line: endPos.lineNumber,
        ch: endPos.column,
      };
    } else {
      line = error.line;
    }

    const erroredDecorators =
      line !== undefined
        ? [
            {
              range: new monaco.Range(line, 1, line, 1),
              options: {
                isWholeLine: true,
                glyphMarginClassName: 'errored-line',
                glyphMarginHoverMessage: {
                  value: error.message,
                },
                hoverMessage: {
                  value: error.message,
                },
              },
            },
          ]
        : [];
    let markers = [];
    if (error.start && error.end) {
      const { start, end } = error;
      if (start.ch === end.ch && start.line === end.line) {
        end.ch++;
      }
      markers = [
        {
          severity: monaco.MarkerSeverity.Error,
          startLineNumber: start.line,
          startColumn: start.ch,
          endLineNumber: end.line,
          endColumn: end.ch,
          message: error.message,
        },
      ];
    }

    monaco.editor.setModelMarkers(model, null, markers);
    this.decorations = this.editor.deltaDecorations(this.decorations, cache.concat(erroredDecorators));
  }

  private initEditor() {
    const { theme } = this.playgroundStore.state;

    this.editor = monaco.editor.create(this.editorTarget.nativeElement, {
      theme: ['light', 'vstudio'].includes(theme) ? 'vs-light' : 'vs-dark',
      minimap: { enabled: false },
      wordWrapColumn: 0,
      wordWrap: 'on',
      dragAndDrop: false,
      glyphMargin: true,
      fixedOverflowWidgets: true,
      trimAutoWhitespace: false,
    } as any);

    this.handleEvents();
  }

  private handleEvents() {
    this.enableAutoResize();

    this.projectStore.projectLoaded$.subscribe(() => {
      const { compilerOptions, files } = this.projectStore.state;
      if (compilerOptions) {
        setCompilerOptions(compilerOptions);
      }
      this.compilerOptions$.next(
        [
          'strict',
          'noImplicitAny',
          'noImplicitThis',
          'noImplicitReturns',
          'alwaysStrict',
          'strictBindCallApply',
          'strictNullChecks',
          'strictFunctionTypes',
          'strictPropertyInitialization',
        ].map((option) => ({ key: option, checked: compilerOptions && !!compilerOptions[option] }))
      );

      this.monacoStore.addFiles(files);

      this.openFile(this.openedFile);
      this.enablePlugins();
    });

    if (this.mode === 'typescript') {
      this.editorWorker.decoratorsUpdated$.subscribe((payload: any) => {
        if (!payload) {
          return;
        }
        const { decorators } = payload;
        Object.keys(decorators).forEach((key) => {
          this.decorationsCache[key] = decorators[key];
        });
        if (Object.keys(decorators).indexOf(this.openedFile) > -1) {
          this.refreshDecorators();
        }

        if (this.loading$.value) {
          this.loading$.next(false);
        }
      });
    } else {
      this.loading$.next(false);
    }

    merge(this.editorWorker.passed$, this.editorWorker.errorInfo$).subscribe(() => this.refreshDecorators());

    this.editorWorker.inspect$.subscribe((options) => this.inspect(options));

    this.playgroundStore.screenShotMode$
      .pipe(filter((mode) => mode === this.mode))
      .subscribe(() => this.prepareScreenShot());
    this.editor.onDidChangeCursorSelection((selectionChange) => {
      if (!this.playgroundStore.state.screenShotMode) {
        this.liveService.detectChangeCursor(this.editor, selectionChange, this.openedFile);

        return;
      }
      this.prepareScreenShot(selectionChange.selection);
    });
  }

  private enablePlugins() {
    const lazyPlugins = ['emmet'];
    if (this.projectStore.state.vimMode) {
      lazyPlugins.push('vim');
    }

    if (this.projectStore.state.record || this.route.snapshot.params?.lessonId) {
      lazyPlugins.push('recording');
    }

    for (const pluginName of lazyPlugins) {
      import(`./plugins/lazy/${pluginName}/${pluginName}.plugin`).then(({ plugIn }) => {
        plugIn(this.editor, this);
      });
    }

    const eagerPlugins = ['go-to-definition', 'prettier'];
    if (this.mode === 'typescript') {
      eagerPlugins.push('auto-import', 'macros', 'go-to-error', 'path-completion');
    }
    for (const pluginName of eagerPlugins) {
      const { plugIn } = require(`./plugins/eager/${pluginName}/${pluginName}.plugin`);
      plugIn(this.editor, this);
    }
  }

  private enableAutoResize() {
    const observer = new ResizeObserver(() => {
      requestAnimationFrame(() => this.editor.layout());
    });
    observer.observe(this.editorTarget.nativeElement);
  }

  private openFile(filePath: string) {
    if (this.openedFile) {
      this.cursorPositions[this.openedFile] = this.editor && this.editor.getPosition();
    }
    this.openedFile = filePath;
    this.monacoStore.openFile(this.editor, filePath);
    if (this.projectStore.state.selectedFile === filePath) {
      this.focus(filePath);
    }
  }

  private focus(selectedFile) {
    if (!this.editor) {
      return;
    }

    this.liveService.activeEditor$.next(this.editor);

    this.refreshDecorators();
    setTimeout(() => {
      const savedPosition = this.cursorPositions[selectedFile];
      this.editor.focus();
      if (savedPosition) {
        this.editor.setPosition(savedPosition);
      }
      this.liveService.detectChangeCursor(
        this.editor,
        {
          selection: this.editor.getSelections(),
          secondarySelections: [],
          source: 'api',
        },
        this.openedFile
      );
    }, 0);
  }

  @debounce()
  private prepareScreenShot(selection?) {
    if (!selection) {
      selection = this.editor.getSelection();
      if (selection.startLineNumber === selection.endLineNumber) {
        selection = undefined;
      }
    }
    const viewModelLines = this.editor['_modelData'].viewModel._lines;
    const { startLineNumber, startColumn, endLineNumber, endColumn } = selection || {
      startLineNumber: 1,
      endLineNumber: viewModelLines.lines.length,
      startColumn: 0,
      endColumn: 0,
    };

    if (selection && startLineNumber === endLineNumber && startColumn === endColumn) {
      return;
    }

    const screenShotBlock = document.getElementById('screenshot');
    screenShotBlock.innerHTML = '';
    const editorBody = this.editorBody.nativeElement;
    editorBody.classList.add('screenshot-mode');
    const width = editorBody.offsetWidth;

    let linesCount = 0;
    for (let start = startLineNumber - 1; start < endLineNumber; start++) {
      linesCount += viewModelLines.lines[start].outputLineCount || 1;
    }

    let realStartLineNumber = 1;
    for (let i = 0; i < startLineNumber - 1; i++) {
      realStartLineNumber += viewModelLines.lines[i].outputLineCount || 1;
    }

    let realEndShift = 0;
    for (let i = startLineNumber - 1; i < endLineNumber; i++) {
      realEndShift += viewModelLines.lines[i].outputLineCount || 1;
    }

    const realEndLineNumber = realStartLineNumber + realEndShift - 1;

    const height = linesCount * 19 + 60;

    const container = editorBody.cloneNode(true) as HTMLElement;
    editorBody.classList.remove('screenshot-mode');
    [].forEach.call(container.querySelectorAll('.view-line'), (line, index) => {
      line.style.top = parseInt(line.style.top, 10) - 19 * (realStartLineNumber - 1) + 'px';
      if (index < realStartLineNumber - 1 || index > realEndLineNumber - 1) {
        line.parentNode.removeChild(line);
      }
    });
    const monacoEditor = container.querySelector<HTMLElement>('.monaco-editor');
    container.style.width = width + 'px';
    monacoEditor.style.width = 'auto';
    monacoEditor.style.height = linesCount * 19 + 'px';
    container.style.height = height + 'px';

    screenShotBlock.appendChild(container);
  }

  private inspect({ filePath, position, offset }) {
    if (
      (this.mode === 'typescript' && !filePath.endsWith('.ts')) ||
      (this.mode === 'html' && !filePath.endsWith('.html'))
    ) {
      return;
    }

    let editor = this.editor;

    if (this.projectStore.state.selectedFile !== filePath) {
      this.projectStore.selectFile(filePath);
    }

    this.monacoStore.openFile(editor, filePath);
    const model = editor.getModel();
    if (position) {
      offset += model.getOffsetAt(position);
    }

    position = model.getPositionAt(offset);
    setTimeout(() => {
      editor.focus();
      editor.setPosition(position);

      editor.revealLineInCenter(position.lineNumber);

      this.highlightLine(position.lineNumber);

      clearTimeout(this.highlightTimeout);

      this.highlightTimeout = setTimeout(() => {
        this.clearHighlighting();
      }, 1500);
    }, 50);
  }

  private highlightLine(lineNumber) {
    const selectedFile = this.openedFile;
    const cache = this.decorationsCache.hasOwnProperty(selectedFile) ? this.decorationsCache[selectedFile] : [];
    const erroredDecorators = [
      {
        range: new monaco.Range(lineNumber, 1, lineNumber, 1),
        options: {
          isWholeLine: true,
          glyphMarginClassName: 'source-map-line',
        },
      },
    ];
    this.decorations = this.editor.deltaDecorations(this.decorations || [], cache.concat(erroredDecorators));
  }

  private clearHighlighting() {
    const selectedFile = this.openedFile;
    const cache = this.decorationsCache.hasOwnProperty(selectedFile) ? this.decorationsCache[selectedFile] : [];
    this.decorations = this.editor.deltaDecorations(this.decorations ? this.decorations : [], cache);
  }

  updateCompilerOptions() {
    const options = this.compilerOptions$.value
      .filter((option) => option.checked)
      .reduce((acc, cur) => {
        acc[cur.key] = true;
        return acc;
      }, {});

    this.projectStore.updateCompilerOptions(Object.keys(options).length ? options : null);
    setCompilerOptions(options);
    if (this.projectStore.state.aot) {
      this.editorWorker.runIvyAOT({});
    }
  }

  setTarget(event: any) {
    const target = event.target.value;
    this.projectStore.setTarget(target);

    this.editorWorker.post({
      type: 'UPDATE_TARGET',
      payload: { files: this.projectStore.state.files, target },
    });
  }
}
